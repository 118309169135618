import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coordinates: {
    latitude: 40.7817,
    longitude: -73.96648,
  },
  city: 'New York',
  state: 'NY',
  zipCode: '10024',
  user: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setZipCode: (state, action) => {
      state.zipCode = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setCoordinates, setCity, setState, setZipCode, setUser } = userSlice.actions;

export default userSlice.reducer;
