import axios from 'axios';
import { auth } from '../firebase';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const client = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Fetch the current user's token and attach it to the request headers
client.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Request Interceptor Error:', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
client.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Response Interceptor Error:', error.response || error.message || error);
    return Promise.reject(error);
  },
);

export default client;
