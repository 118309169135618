import React, { useEffect, useContext, useState } from 'react';
import { PreferencesContext } from '../context/theme';
import {
  updateShopifyData,
  updateLightspeedData,
  updateSquareData,
  updateCloverData,
} from '../api/authApi';
import {
  setSellerListings,
  setSeller,
  setSellerDiscounts,
  setSellerLocations,
} from '../redux/features/sellerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logOutUser } from '../redux/features/authSlice';
import { auth } from '../firebase';
import { resetAuthState } from '../redux/features/sellerSlice';
import { ButtonGeneral } from '../components/buttonGeneral';
import './styles/Inventory.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import logo from '../PIELogo.png';
import Spinner from '../components/Spinner';

const generateRandomString = (length = 16) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
};

const Inventory = () => {
  const dispatch = useDispatch();
  const { theme } = useContext(PreferencesContext);
  const navigate = useNavigate();
  const location = useLocation();
  const seller = useSelector((state) => state.seller.seller);
  const locations = useSelector((state) => state.seller.locations);
  const discounts = useSelector((state) => state.seller.discounts);
  const listings = useSelector((state) => state.seller.listings);
  const provider = useSelector((state) => state.seller.provider);
  const { shopDomain } = location.state || {};
  const [activeTab, setActiveTab] = useState('Listings');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authorizationResult, setAuthorizationResult] = useState(null);

  const isAuthorized = Array.isArray(seller.oauthTokens) && seller.oauthTokens.length > 0;

  console.log('isAuthorized', isAuthorized);

  console.log('authorization result', authorizationResult);

  console.log('shopdomain', shopDomain);
  console.log('provider', provider);

  const formatNumberWithCommas = (value) => {
    let number = typeof value === 'string' ? parseFloat(value) : value;

    if (typeof number !== 'number' || isNaN(number)) {
      return value;
    }

    return number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    console.log('params', params);
    const successParam = params.get('success');
    console.log('success param', successParam);
    if (successParam === '1') {
      setAuthorizationResult(1);
    } else if (successParam === '0') {
      setAuthorizationResult(0);
    }
  }, [location.search]);

  // Adjusted useEffect
  useEffect(() => {
    const fetchSellerData = async () => {
      if (isAuthorized || authorizationResult) {
        await fetchData();
      }
    };

    fetchSellerData();
  }, [isAuthorized, authorizationResult]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      let data;

      if (provider.toLowerCase() === 'shopify') {
        data = await updateShopifyData();
      } else if (provider.toLowerCase() === 'lightspeed') {
        data = await updateLightspeedData();
      } else if (provider.toLowerCase() === 'square') {
        data = await updateSquareData();
      } else {
        throw new Error('Unsupported provider');
      }

      const { seller: updatedSeller, listings, discounts, locations } = data;

      dispatch(setSeller(updatedSeller));
      dispatch(setSellerListings(listings));
      dispatch(setSellerDiscounts(discounts));
      dispatch(setSellerLocations(locations));
    } catch (error) {
      console.error(`Error updating data for provider ${provider}:`, error);
      setError('Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      dispatch(logOutUser());
      dispatch(resetAuthState());
      navigate('/');
    } catch (err) {
      console.error('Error during logout:', err);
    }
  };

  const authorize = () => {
    setError(null);

    if (!isAuthorized && provider) {
      try {
        const csrfToken = generateRandomString(64);
        const sellerUid = seller.seller_uid;
        const web = true;
        const stateData = { csrfToken, sellerUid, web };
        const state = btoa(JSON.stringify(stateData));

        let oauthUrl;

        if (provider.toLowerCase() === 'shopify') {
          oauthUrl = `https://api.pienet.net/shopify/auth?shop=${encodeURIComponent(
            shopDomain,
          )}&state=${state}`;
        } else {
          oauthUrl = `https://api.pienet.net/api/_auth/oauth/${provider.toLowerCase()}?state=${state}`;
        }
        window.location.href = oauthUrl;
      } catch (error) {
        console.error('Authorization error:', error);
        setError(error.message || 'An error occurred during authorization.');
      }
    }
  };

  function renderContent() {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return (
        <div className='error-message'>
          <p>{error}</p>
          <ButtonGeneral
            title={`Try Again (${provider})`}
            onPress={fetchData}
          />
        </div>
      );
    }

    if (!isAuthorized && provider) {
      return (
        <div className='container'>
          <p className='subtitle'>Why does PIE need access to your {provider} account?</p>
          <p className='text'>
            By granting PIE access to your {provider} account, we can market your business to nearby
            customers effectively.
          </p>
          <ButtonGeneral
            title={`Authorize (${provider})`}
            onPress={authorize}
          />
        </div>
      );
    }

    if (authorizationResult === 'success') {
      return (
        <div className='center'>
          <p className='subtitle'>Authorization Successful!</p>
          <ButtonGeneral
            title='Fetch Data'
            onPress={fetchData}
          />
        </div>
      );
    }

    if (authorizationResult === 'error') {
      return (
        <div className='center'>
          <p className='text'>Hmm, something went wrong.</p>
          <ButtonGeneral
            title={`Authorize (${provider})`}
            onPress={authorize}
          />
        </div>
      );
    }

    return (
      <>
        <div className='tabs'>
          <button
            className={`tab-button ${activeTab === 'Listings' ? 'active' : ''}`}
            onClick={() => setActiveTab('Listings')}>
            Listings
          </button>
          <button
            className={`tab-button ${activeTab === 'Discounts' ? 'active' : ''}`}
            onClick={() => setActiveTab('Discounts')}>
            Discounts
          </button>
          <button
            className={`tab-button ${activeTab === 'Locations' ? 'active' : ''}`}
            onClick={() => setActiveTab('Locations')}>
            Locations
          </button>
        </div>
        <div className='tab-content'>
          {activeTab === 'Listings' && renderListings()}
          {activeTab === 'Discounts' && renderDiscounts()}
          {activeTab === 'Locations' && renderLocations()}
        </div>
      </>
    );
  }

  const renderListings = () => (
    <div className='cards-container'>
      {listings.map((listing) => (
        <div
          key={listing.id}
          className='card'>
          {listing.images && listing.images.length > 0 ? (
            <img
              src={listing.images[0]}
              alt={listing.name}
              className='card-image'
            />
          ) : (
            <img
              src={logo}
              alt='Logo Placeholder'
              className='card-image'
            />
          )}
          <div className='card-footer'>
            <h3 className='card-title listing-title'>{listing.name}</h3>
            {listing.price == 0 ? (
              <p className='card-price'>Price upon request</p>
            ) : listing.multi_price ? (
              <p className='card-price'>From ${formatNumberWithCommas(listing.price)}</p>
            ) : (
              <p className='card-price'>${formatNumberWithCommas(listing.price)}</p>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const renderDiscounts = () => (
    <div className='cards-container'>
      {discounts.map((discount) => (
        <div
          key={discount.id}
          className='card discount-card'>
          <div className='deal-label'>Deal</div>
          <div className='discount-amount'>
            {discount.amount == 0
              ? '*Rules Apply*'
              : discount.percentage
              ? `${discount.amount}%`
              : `$${formatNumberWithCommas(discount.amount)}`}
          </div>
          <div className='card-footer'>
            <h3 className='card-title discount-title'>{discount.name}</h3>
          </div>
        </div>
      ))}
    </div>
  );

  const renderLocations = () => (
    <div className='cards-container'>
      {locations.map((location) => (
        <div
          key={location.id}
          className='card location-card'>
          <FaMapMarkerAlt className='location-icon' />
          <div className='location-address'>
            <p>{location.address_line_1}</p>
            {location.address_line_2 && <p>{location.address_line_2}</p>}
            <p>
              {location.city}, {location.state} {location.zip_code}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`inventory-container ${theme.mode}`}>
      <header className='inventory-header'>
        {isAuthorized && <h1>Hello, {seller?.seller_name}!</h1>}
        <div className='logout-button-container'>
          <ButtonGeneral
            title='Log Out'
            onPress={handleLogout}
            color={theme.colors.softGray}
            borderColor={theme.colors.onBackground}
            border
            margin={5}
          />
        </div>
      </header>
      {renderContent()}
    </div>
  );
};

export default Inventory;
