import React, { useContext } from 'react';
import { PreferencesContext } from '../context/theme';
import './InputGeneral.css';

export const InputGeneral = React.forwardRef(
  (
    {
      errors,
      iconR,
      iconL,
      label,
      autoCapitalize,
      secure = false,
      bgColor,
      editable = true,
      borderW,
      type,
      radius = 80,
      max,
      multiline = false,
      onSubmit,
      labelComponent,
      contentType,
      scroll,
      padding,
      ...textInputProps
    },
    ref,
  ) => {
    // Accept ref as a second argument
    const { theme } = useContext(PreferencesContext);

    const handleOnSubmitEditing = (e) => {
      if (onSubmit) {
        onSubmit();
      }
    };

    return (
      <div
        className='input-general-container'
        style={{ padding: padding || 10 }}>
        {label && (
          <div className='input-general-label-container'>
            <span className='input-general-text'>{label}</span>
          </div>
        )}
        {labelComponent}
        <div
          className='input-general-input-container'
          style={{
            borderRadius: radius,
            borderWidth: borderW || 1,
            borderColor: theme.colors.black,
            backgroundColor: bgColor || theme.colors.background,
            paddingRight: iconR ? 10 : null,
            paddingLeft: iconL ? 10 : null,
          }}>
          {iconL && <span className='icon-left'>{iconL}</span>}
          <input
            ref={ref} // Attach ref to the input element
            type={secure ? 'password' : 'text'}
            className={`input-general-text-input ${multiline ? 'multiline' : ''}`}
            style={{
              color: theme.colors.onBackground,
              minHeight: multiline ? 120 : 50,
              maxHeight: multiline ? 120 : 50,
              paddingHorizontal: 10,
              flex: 1,
            }}
            placeholder={textInputProps.placeholder}
            disabled={!editable}
            autoCapitalize={autoCapitalize || 'sentences'}
            maxLength={max || 1000}
            onKeyDown={(e) => e.key === 'Enter' && handleOnSubmitEditing(e)}
            {...textInputProps}
          />
          {iconR && <span className='icon-right'>{iconR}</span>}
        </div>
        {errors && <div className='input-general-error-text'>{errors}</div>}
      </div>
    );
  },
);
