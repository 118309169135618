import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/AllSet.css';
import { ButtonGeneral } from '../components/buttonGeneral';
import { auth } from '../firebase';
import { logOutUser } from '../redux/features/authSlice';
import { resetAuthState } from '../redux/features/sellerSlice';
import { useDispatch } from 'react-redux';

const getDeviceInfo = () => {
  const ua = navigator.userAgent || window.opera;

  // iOS detection
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'iOS';
  }

  // Android detection
  if (/android/i.test(ua)) {
    return 'Android';
  }

  // Other devices
  return 'Other';
};

const AllSet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [device, setDevice] = useState('Other');

  useEffect(() => {
    const deviceType = getDeviceInfo();
    setDevice(deviceType);
  }, []);

  const handleBackToLogin = async () => {
    await auth.signOut();
    dispatch(logOutUser());
    dispatch(resetAuthState());
    navigate('/login');
  };

  return (
    <div className='all-set-container'>
      <div className='all-set-content'>
        <h1 className='all-set-title'>All Set!</h1>
        {device === 'iOS' ? (
          <p className='all-set-message'>
            Your setup is complete. Open the app on your iOS device.
          </p>
        ) : (
          <p className='all-set-message'>Your setup is complete. Please log back in to continue.</p>
        )}
        {device !== 'iOS' && (
          <ButtonGeneral
            title='Log In'
            onPress={handleBackToLogin}
            color={'transparent'}
            fontSize={20}
            width
          />
        )}
      </div>
    </div>
  );
};

export default AllSet;
