import React from 'react';
import './Spinner.css';

const Spinner = () => (
  <div
    className='spinner-container'
    role='status'
    aria-live='polite'>
    <div
      className='spinner'
      aria-hidden='true'></div>
    <p>Loading...</p>
  </div>
);

export default Spinner;
