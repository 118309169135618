import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ButtonGeneral } from '../components/buttonGeneral';
import { InputGeneral } from '../components/inputGeneral';
import { PieIcons } from '../components/icons/PIE_Icons';
import logo from '../PIELogo.png';
import { signUp } from '../redux/features/authSlice';
import './styles/SignUp.css';
import { setSeller } from '../redux/features/sellerSlice';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      navigate('/choose-pos');
    }
  }, [user, navigate]);

  const signUpValidationSchema = yup.object().shape({
    name: yup.string().required('Business Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup
      .string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required'),
    category: yup
      .string()
      .oneOf(
        [
          'Fashion',
          'Hair & Beauty',
          'Food & Beverage',
          'House & Home',
          'Pets',
          'Cars',
          'Health & Wellness',
          'Tech & Electronics',
          'Kids & Babies',
          'Other',
        ],
        'Select a valid category',
      )
      .required('Category is required'),
    phoneNumber: yup
      .string()
      .matches(
        /^\+?[1-9]\d{1,14}$/,
        'Phone number is not valid. It should contain only numbers and can include a leading +',
      )
      .required('Phone Number is required'),
    website: yup
      .string()
      .nullable()
      .matches(/^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\S*)?$/, 'Invalid website URL')
      .notRequired(),
  });

  const passwordRef = useRef(null);

  const showPass = () => {
    setVisible(!visible);
  };

  const showPass_2 = () => {
    setVisible2(!visible2);
  };

  const initialValues = {
    name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    category: '',
    phoneNumber: '',
    website: '',
  };

  const handleSignUp = async (values) => {
    setLoading(true);
    try {
      const sellerResponse = await dispatch(signUp(values)).unwrap();
      dispatch(setSeller(sellerResponse));
      setLoading(false);
    } catch (error) {
      console.error('SIGN UP ERROR:', error);
      setLoading(false);
    }
  };

  return (
    <div className='signup-container'>
      <button
        className='back-button'
        type='button'
        onClick={() => navigate('/')}>
        <span>&lt; Back</span>
      </button>

      <img
        src={logo}
        alt='Shop On PIE Logo'
        className='logo'
      />

      <Formik
        initialValues={initialValues}
        validationSchema={signUpValidationSchema}
        onSubmit={(values) => handleSignUp(values)}>
        {({ handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
          <form
            className='form-container'
            onSubmit={handleSubmit}>
            <h2 className='title'>Become A Seller</h2>

            <InputGeneral
              placeholder='Business Name'
              label='Business Name'
              value={values.name}
              type='text'
              onChange={(e) => setFieldValue('name', e.target.value)}
              onBlur={handleBlur('name')}
              errors={errors.name && touched.name ? errors.name : undefined}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            />

            <InputGeneral
              placeholder='Email'
              label='Email'
              value={values.email}
              type='email'
              onChange={(e) => setFieldValue('email', e.target.value)}
              onBlur={handleBlur('email')}
              errors={errors.email && touched.email ? errors.email : undefined}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  passwordRef.current.focus();
                }
              }}
            />

            <InputGeneral
              placeholder='Password'
              label='Password'
              value={values.password}
              onChange={(e) => setFieldValue('password', e.target.value)}
              onBlur={handleBlur('password')}
              errors={errors.password && touched.password ? errors.password : undefined}
              secure={!visible}
              iconR={
                <PieIcons
                  name='eye'
                  draw={{
                    size: 28,
                    onClick: showPass,
                    isVisible: visible,
                  }}
                />
              }
              ref={passwordRef} // Attach ref to password input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  // Focus confirm password
                  document.getElementById('confirmPassword').focus();
                }
              }}
            />

            <InputGeneral
              placeholder='Confirm Password'
              label='Confirm Password'
              value={values.passwordConfirmation}
              onChange={(e) => setFieldValue('passwordConfirmation', e.target.value)}
              onBlur={handleBlur('passwordConfirmation')}
              errors={
                errors.passwordConfirmation && touched.passwordConfirmation
                  ? errors.passwordConfirmation
                  : undefined
              }
              secure={!visible2}
              iconR={
                <PieIcons
                  name='eye'
                  draw={{
                    size: 28,
                    onClick: showPass_2,
                    isVisible: visible2,
                  }}
                />
              }
              id='confirmPassword'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  // Focus category
                  document.getElementById('category').focus();
                }
              }}
            />

            <div className='input-group'>
              <label htmlFor='category'>What Category do you sell in the most?</label>
              <select
                id='category'
                name='category'
                value={values.category}
                onChange={(e) => setFieldValue('category', e.target.value)}
                onBlur={handleBlur('category')}
                className={`input-general ${
                  errors.category && touched.category ? 'input-error' : ''
                }`}>
                <option
                  value=''
                  label='Select category'
                />
                <option
                  value='Fashion'
                  label='Fashion'
                />
                <option
                  value='Hair & Beauty'
                  label='Hair & Beauty'
                />
                <option
                  value='Food & Beverage'
                  label='Food & Beverage'
                />
                <option
                  value='House & Home'
                  label='House & Home'
                />
                <option
                  value='Pets'
                  label='Pets'
                />
                <option
                  value='Cars'
                  label='Cars'
                />
                <option
                  value='Health & Wellness'
                  label='Health & Wellness'
                />
                <option
                  value='Tech & Electronics'
                  label='Tech & Electronics'
                />
                <option
                  value='Kids & Babies'
                  label='Kids & Babies'
                />
                <option
                  value='Other'
                  label='Other'
                />
              </select>
              {errors.category && touched.category && (
                <div className='error-message'>{errors.category}</div>
              )}
            </div>

            <InputGeneral
              placeholder='Phone Number'
              label='Phone Number'
              value={values.phoneNumber}
              type='tel'
              max={10}
              onChange={(e) => setFieldValue('phoneNumber', e.target.value)}
              onBlur={handleBlur('phoneNumber')}
              errors={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : undefined}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  document.getElementById('website').focus();
                }
              }}
            />

            <InputGeneral
              placeholder='Website'
              label='Website'
              value={values.website}
              type='url'
              onChange={(e) => setFieldValue('website', e.target.value)}
              onBlur={handleBlur('website')}
              errors={errors.website && touched.website ? errors.website : undefined}
              id='website'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            />

            <ButtonGeneral
              title='Sign Up'
              onPress={handleSubmit}
              fontSize={20}
              width
              loading={loading}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;
