import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { createSeller, getSeller_ } from '../../api/authApi';

export const logIn = createAsyncThunk(
  'auth/logIn',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      // Authenticate the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      return await getSeller_(user.uid);
    } catch (error) {
      console.error('Error during login:', error);
      let message;
      switch (error.code) {
        case 'auth/user-not-found':
          message = 'No user found.';
          break;
        case 'auth/wrong-password':
          message = 'Incorrect email/password.';
          break;
        case 'auth/invalid-email':
          message = 'Invalid email address.';
          break;
        case 'auth/user-disabled':
          message = 'This user has been disabled.';
          break;
        default:
          message = 'An error occurred during login. Please try again.';
      }
      return rejectWithValue(message);
    }
  },
);

export const signUp = createAsyncThunk('auth/signUp', async (sellerData, { rejectWithValue }) => {
  const { email, password, name, category, phoneNumber, website } = sellerData;

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Step 3: Apply to be a seller via API
    const sellerApplication = {
      seller_uid: user.uid,
      seller_name: name,
      seller_email: email,
      category,
      seller_phone: phoneNumber,
      seller_website: website,
    };

    const sellerResponse = await createSeller(sellerApplication);

    return sellerResponse;
  } catch (error) {
    console.error('Error during sign up:', error);

    // Handle Firebase auth errors
    if (error.code) {
      let message;
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = 'Email is already in use.';
          break;
        case 'auth/invalid-email':
          message = 'Invalid email address.';
          break;
        case 'auth/weak-password':
          message = 'Password is too weak.';
          break;
        default:
          message = 'An error occurred during sign up. Please try again.';
      }
      return rejectWithValue(message);
    }

    // Handle API errors from createSeller
    return rejectWithValue(error.message || 'Failed to sign up.');
  }
});

const initialState = {
  user: null,
  seller: null,
  error: null,
  isLoadingLogIn: false,
  isLoadingSignUp: false, // New state for sign-up loading
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOutUser: () => initialState,
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle logIn
    builder
      .addCase(logIn.pending, (state) => {
        state.isLoadingLogIn = true;
        state.error = null;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isLoadingLogIn = false;
        state.user = action.payload;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.isLoadingLogIn = false;
        state.error = action.payload;
      });

    // **Handle signUp**
    builder
      .addCase(signUp.pending, (state) => {
        state.isLoadingSignUp = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.isLoadingSignUp = false;
        state.user = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isLoadingSignUp = false;
        state.error = action.payload;
      });
  },
});

export const { logOutUser, setSeller } = authSlice.actions;

export default authSlice.reducer;
