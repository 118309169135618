import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import searchReducer from './features/searchSlice';
import userReducer from './features/userSlice';
import authReducer from './features/authSlice';
import sellerReducer from './features/sellerSlice';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['seller', 'auth'], // only persist seller and auth slices
};

// Combine reducers
const rootReducer = combineReducers({
  search: searchReducer,
  user: userReducer,
  auth: authReducer,
  seller: sellerReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Redux Persist actions need to be ignored in serializable checks
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: ['search.listings'], // Keep your existing ignoredPaths
      },
    }),
});

export const persistor = persistStore(store);

export default store;
