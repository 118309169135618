import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  seller: null,
  sellerToken: false,
  listings: [],
  discounts: [],
  locations: [],
  creatingAccount: false,
  firstTimeLogin: false,
  loggingIn: false,
  provider: null,
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSeller: (state, action) => {
      state.seller = action.payload;
    },
    setSellerToken(state, action) {
      state.sellerToken = action.payload;
    },
    setSellerListings: (state, action) => {
      state.listings = action.payload;
    },
    setSellerLocations: (state, action) => {
      state.locations = action.payload;
    },
    setSellerDiscounts: (state, action) => {
      state.discounts = action.payload;
    },
    setCreatingAccount: (state, action) => {
      state.creatingAccount = action.payload;
    },
    resetAuthState: () => initialState,
    setFirstTimeLogin: (state, action) => {
      state.firstTimeLogin = action.payload;
    },
    setLoggingIn: (state, action) => {
      state.loggingIn = action.payload;
    },
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
  },
});

export const {
  setUser,
  setSeller,
  setSellerListings,
  setSellerDiscounts,
  setSellerLocations,
  setCreatingAccount,
  setFirstTimeLogin,
  resetAuthState,
  setSellerToken,
  setLoggingIn,
  setProvider,
} = sellerSlice.actions;

export default sellerSlice.reducer;
