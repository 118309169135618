import React, { useContext } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { PreferencesContext } from '../../context/theme';

export const Eye = ({ size, color, onClick, isVisible }) => {
  const { theme } = useContext(PreferencesContext);

  return (
    <div
      onClick={onClick}
      style={{ cursor: 'pointer' }}>
      {isVisible ? (
        <IoMdEye
          size={size || 28}
          color={color || theme.colors.onBackground}
        />
      ) : (
        <IoMdEyeOff
          size={size || 28}
          color={color || theme.colors.onBackground}
        />
      )}
    </div>
  );
};
