import React from 'react';
import './styles/TermsConditions.css';

const TermsConditions = () => {
  return (
    <div className='terms-container'>
      <h1 className='terms-text'>Terms & Conditions</h1>
      <div className='terms-content'>
        {/* Replace the following with your actual terms and conditions text */}

        <p>LAST UPDATED: October 28, 2024</p>
        <p>
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY. THESE TERMS INCLUDE A MANDATORY ARBITRATION
          PROVISION AND CLASS ACTION WAIVER PROVISION, WHICH AFFECT YOUR RIGHTS ABOUT HOW TO RESOLVE
          ANY DISPUTE WITH THE PIE NETWORK, INC. BY ACCESSING OR USING OUR WEBSITE, APPLICATIONS OR
          OTHER PRODUCTS OR SERVICES (TOGETHER, &ldquo;THE PIE NETWORK SERVICE&rdquo;), YOU AGREE TO
          BE BOUND BY ALL TERMS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE
          (&ldquo;TERMS&rdquo;). IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT ACCESS OR USE THE
          PIE NETWORK SERVICE.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT THERE ARE RISKS ASSOCIATED WITH UTILIZING AN INTERNET-BASED
          MARKETPLACE AND INTERACTING WITH OTHER USERS IN PERSON AS OUTLINED IN SECTION 17.
        </p>
        <p>
          These Terms apply to your access to and use of The PIE Network Service provided by The PIE
          Network, Inc. (&ldquo;The PIE Network,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;).
          Additional terms (including, but not limited to, the terms of social media services,
          third-party payment processors, and third-party fulfillment providers) may apply to
          particular functionalities and features related to The PIE Network Service.
        </p>
        <p>
          Note: From time to time The PIE Network introduces new features that may only be available
          to certain users. Provisions of these Terms of Service relating to such new features,
          including, at the present, the electronic payments and fulfillment solutions, may not
          apply to all users.
        </p>
        <p>1. Eligibility</p>
        <p>
          ONLY USERS WHO ARE EIGHTEEN (18) YEARS OF AGE OR OLDER MAY REGISTER FOR OR USE THE PIE
          NETWORK SERVICE.&nbsp;The PIE Network Service is not targeted towards, nor intended for
          use by, anyone under the age of eighteen (18). If you are below the age of eighteen (18),
          you may use The PIE Network Service only under the supervision of a parent or legal
          guardian who agrees to be bound by these Terms and any applicable additional terms. You
          further represent and warrant that you: (a) have not previously been suspended or removed
          from using The PIE Network Service; (b) are legally permitted to, and do, live in the
          United States or one of its territories, and (c) may enter into this agreement without
          violating any other agreement to which you are a party. If you are registering to use The
          PIE Network Service on behalf of a legal entity, you further represent and warrant that
          (i) such legal entity is duly organized and validly existing under the applicable laws of
          the jurisdiction of its organization, and (ii) you are duly authorized by such legal
          entity to act on its behalf.
        </p>
        <p>2. Account Registration and Security Responsibilities</p>
        <p>
          In order to access certain parts of The PIE Network Service, you may be required to create
          a The PIE Network account (an &ldquo;Account&rdquo;) or a seller payment account
          (&ldquo;Stripe Account&rdquo;) with Stripe, Inc. (&ldquo;Stripe&rdquo;). In connection
          with creating an Account or a Stripe Account, you must provide certain information
          (&ldquo;Registration Data&rdquo;) and answer all questions or fields marked
          &ldquo;required.&rdquo; You agree to: (a) provide true, accurate, current and complete
          Registration Data; (b) maintain and update such Registration Data to keep it true,
          accurate, current and complete; (c) maintain the security of your Account and the Stripe
          Account, including by maintaining the security and confidentiality of your login
          credentials; and (d) consent to allow The PIE Network to contact you for the purpose of
          confirming some or all of your Registration Data, to conduct research and to resolve
          disputes, as The PIE Network may elect to do from time to time.
        </p>
        <p>3. Purchases From Other Users.</p>
        <p>
          A. Cash and Other Payments Determined by Users.&nbsp;A buyer may, by agreement with the
          seller, elect to make payment by cash, check or other payment method accepted by the
          seller. Such payments are made directly between the buyer and the seller when they meet in
          person to complete their purchase and sale transaction, pursuant to terms they determine.
          The PIE Network is not a party to such transactions, and does not facilitate such
          transactions, refunds or returns in any manner.
        </p>
        <p>
          B. The PIE Network Payment Solution.&nbsp;A buyer and seller may instead pay with and
          accept credit cards and other electronic payment methods (each an &ldquo;Electronic
          Payment Method&rdquo;) through The PIE Network Service. The PIE Network has integrated
          with Stripe, a payment processing service, to allow sellers to accept certain Electronic
          Payment Methods from buyers using Stripe&rsquo;s payment processing services (&ldquo;The
          PIE Network Payment Solution&rdquo;). Buyers who wish to use The PIE Network Payment
          Solution must register an Electronic Payment Method with The PIE Network, and sellers must
          enroll for the service through Stripe. Enrollment in and usage of The PIE Network Payment
          Solution is voluntary, so buyers should note that some sellers may not accept Electronic
          Payment Methods, or may only accept Electronic Payment Methods for certain transactions.
          The PIE Network, in its sole discretion, may from time to time impose limits on your
          ability to make and/or receive payments through The PIE Network Payment Solution.
          Additionally, Stripe may impose its own limits and limitations on a seller&rsquo;s use of
          The PIE Network Payment Solution. For instance, a seller&rsquo;s enrollment in The PIE
          Network Payment Solution is subject to Stripe&rsquo;s confirmation that the seller meets
          Stripe&rsquo;s enrollment criteria. Sellers should refer to Section 2(d) below for
          information about the impact of failing to meet Stripe&rsquo;s enrollment criteria.
        </p>
        <p>
          i. Buyers.&nbsp;As with any purchases made using cash, all purchases made using The PIE
          Network Payment Solution are made directly between the buyer and the seller when they
          complete their purchase and sale transaction, pursuant to the terms they determine. When
          you initiate a payment through The PIE Network Payment Solution, the seller processes your
          Electronic Payment Method using Stripe&rsquo;s payment processing service. The PIE Network
          is not a party to purchase and sale transactions completed using The PIE Network Payment
          Solution, and disclaims any and all responsibility to facilitate such transactions, except
          to provide an interface through which you can provide your Electronic Payment Method to
          Stripe to process on behalf of the seller. The PIE Network further disclaims any and all
          responsibility to facilitate or provide refunds or returns in any manner, other than as
          expressly provided in the&nbsp;<span>Buyer Protection Policy</span>.
        </p>
        <p>
          ii. Sellers.&nbsp;By using The PIE Network Payment Solution to accept Electronic Payment
          Methods, you are entering into an agreement with Stripe subject to the terms of the&nbsp;
          <span>Stripe Connected Account Agreement</span>, which includes the&nbsp;
          <span>Stripe Terms of Service</span>&nbsp;(collectively, the &ldquo;Stripe Services
          Agreement&rdquo;). Notwithstanding anything to the contrary in the Stripe Services
          Agreement, you will not have the right to have Stripe, and will not request that Stripe,
          transfer any buyer data Stripe collects via The PIE Network Payment Solution to an
          alternative payment processor. Except for the foregoing restriction that supersedes any
          rights you may have in the Stripe Services Agreement, the Stripe Services Agreement is
          separate from these Terms. The PIE Network is not a party to the Stripe Services Agreement
          and will not be liable or responsible for the payment services provided by Stripe. If
          Stripe discontinues providing services in connection with The PIE Network Payment
          Solution, you authorize Stripe to share your payment method information with an
          alternative third-party payment processor that is or will be integrated into The PIE
          Network Payment Solution.
        </p>
        <p>
          iii. Seller Fees.&nbsp;You agree to pay the service fees (&ldquo;Service Fees&rdquo;) for
          the sales transactions you make using The PIE Network Payment Solution. The Service Fees
          include Electronic Payment Method processing fees to Stripe and service fees to The PIE
          Network. The PIE Network reserves the right to change the Service Fees from time to time.
        </p>
        <p>
          iv. Receiving Sales Proceeds.&nbsp;Upon completion of a sale in which the buyer uses The
          PIE Network Payment Solution, if you have not previously set up a Stripe Account, then you
          must set up a Stripe Account in accordance with the requirements specified by Stripe to
          receive the payment via The PIE Network Payment Solution. STRIPE MUST ACCEPT YOUR
          APPLICATION TO USE THE PIE NETWORK PAYMENT SOLUTION BEFORE YOU CAN RECEIVE SALES PROCEEDS
          VIA THE PIE NETWORK PAYMENT SOLUTION. IF STRIPE REJECTS YOUR APPLICATION OR YOU FAIL TO
          SET UP A STRIPE ACCOUNT WITHIN 90 DAYS AFTER COMPLETION OF A SALE PROCESSED BY STRIPE
          THROUGH THE PIE NETWORK PAYMENT SOLUTION, THEN STRIPE MAY DISABLE OR LIMIT YOUR ABILITY TO
          RECEIVE SALES PROCEEDS VIA THE PIE NETWORK PAYMENT SOLUTION.
        </p>
        <p>
          v. Acceptable Use Violations.&nbsp;YOUR RIGHT AND/OR ABILITY TO RECEIVE SALES PROCEEDS VIA
          THE PIE NETWORK PAYMENT SOLUTION MAY BE REVOKED, DISABLED OR LIMITED IF THE PURCHASE OR
          SALE VIOLATES SECTION 7 (ACCEPTABLE USE) OF THESE TERMS, INCLUDING FOR SALES THAT VIOLATE
          THE PROHIBITED ITEMS GUIDELINES.
        </p>
        <p>
          vi. Transactions Final.&nbsp;Regardless of the payment method chosen, ALL PURCHASES ARE
          FINAL, AND THERE ARE NO REFUNDS, UNLESS YOU AND THE SELLER OTHERWISE AGREE AND MAKE
          ARRANGEMENTS FOR A REFUND. The PIE Network will not be responsible for facilitating
          refunds,&nbsp;<span>other than as expressly provided with respect to the&nbsp;</span>
          <span>Buyer Protection Policy</span>.
        </p>
        <p>
          vii. Taxes.&nbsp;It is your responsibility to determine what, if any, taxes apply to each
          transaction you complete via The PIE Network Service, including, for example, sales, use,
          value added, and similar taxes. It is also your responsibility to withhold, collect,
          report and remit the correct taxes to the appropriate tax authorities. The PIE Network is
          not responsible for withholding, collecting, reporting, or remitting any sales, use, value
          added, or similar tax arising from any transaction you complete via The PIE Network
          Service.
        </p>
        <p>
          C. Shipping.&nbsp;From time to time, The PIE Network may, in its sole discretion and
          pursuant to its&nbsp;<span>Shipping Policy</span>, recommend a third party to allow
          sellers to ship purchased items to buyers. &nbsp;The PIE Network is not a party to
          transactions conducted between buyers and sellers, or to the shipping of items from
          sellers to buyers, and, other than as expressly provided in the&nbsp;
          <span>Shipping Policy</span>, The PIE Network will not be liable for, and you release us
          from any liability to you for, any losses, damages, or delays related to
          shipping.&nbsp;&nbsp;You agree not to use The PIE Network shipping service to mail or
          cause to be mailed, or ship or cause to be shipped, any item purchased and sold through
          The PIE Network in a manner that violates the law and/or United States Postal Service or
          shipping regulations, including but not limited to&nbsp;U.S. Postal Service Publication
          52.&nbsp; You further warrant that any item you mail or ship contains no weapons,
          ammunition, explosives, living or infectious biological matter, human remains,
          pornography, alcohol, prescription drugs, illegal drugs, currency, dangerous goods,
          hazardous goods, or other goods that may not be shipped or mailed by law. As a seller, you
          assume full responsibility for compliance with all applicable laws and regulations,
          including those regarding mailing and shipping.&nbsp; Anyone who sends, or causes to be
          sent, a prohibited, illegal, or improperly packaged or labeled material can be subject to
          legal penalties such as civil penalties, fines and/or imprisonment, including but not
          limited to those specified in 18 U.S.C. &sect; 1716 and 39 U.S.C. &sect; 3018.
        </p>
        <p>
          i. Sellers. Sellers who choose to post a product eligible for shipping must ship their
          items in accordance with The PIE Network&rsquo;s Shipping Policy. When a seller accepts a
          buyer&rsquo;s offer to purchase an item to be shipped, the seller must mail the item and
          upload valid tracking within 3 days of accepting the offer. Orders must be shipped by a
          qualified carrier with a method that provides last mile tracking. Orders must be confirmed
          delivered by one of the carriers within 30 days of the order being available for
          fulfillment. Merchants that do not meet these requirements are at risk of suspension. The
          seller may cancel an accepted offer up until the package is initially scanned for mailing.
          The buyer&rsquo;s payment, minus The PIE Network&rsquo;s service fee, will be released to
          the seller typically 3 business days after delivery, provided that no Buyer Protection
          claims are made by the buyer. Buyer Protection claims may result in delay and/or
          cancellation of payment being released to the seller.
        </p>
        <p>
          ii. Buyers. For these items, the buyer is solely responsible to pay the cost of shipping
          the item from the seller. Buyers can cancel an offer to purchase an item for shipping up
          until the seller has shipped the package. When a seller accepts a buyer&rsquo;s offer to
          purchase an item for shipping, The PIE Network will charge or put a hold on the
          buyer&rsquo;s method of payment, pending delivery of the item. If there are any problems
          with the delivery or the item itself, please consult The PIE Network&nbsp;
          <span>Buyer Protection Policy</span>. For any undisclosed damage, incorrect or missing
          items, or items that are not as described, buyers must contact The PIE Network within 2
          days of delivery, otherwise The PIE Network will deem the transaction closed and release
          the buyer&rsquo;s payment to the seller. Please review The PIE Network&nbsp;
          <span>Buyer Protection Policy</span>&nbsp;to learn more. Buyers may also be given the
          option within The PIE Network Service to confirm the item purchased is as described after
          receiving and inspecting it. When done, The PIE Network will also deem the transaction
          closed and release the buyer&rsquo;s payment to the seller.
        </p>
        <p>4. Terms of Sale for The PIE Network&rsquo;s Paid Services.</p>
        <p>
          From time to time, The PIE Network may make certain services available for a fee in
          connection with The PIE Network Service (&ldquo;Paid Services&rdquo;). The following terms
          of sale apply solely to your purchase of Paid Services from The PIE Network.
        </p>
        <p>
          A. Fees.&nbsp;Unless otherwise agreed upon by The PIE Network in writing, the fees payable
          in connection with any Paid Services (&ldquo;Fees&rdquo;) will be specified via The PIE
          Network Service. All Fees are denominated in U.S. dollars and are exclusive of any
          applicable taxes.
        </p>
        <p>
          B. Payment Method.&nbsp;You may only pay Fees using valid payment methods acceptable to
          us, as specified via The PIE Network Service. You represent and warrant that you are
          authorized to use the payment method you designate via The PIE Network Service. You
          authorize us to charge your designated payment method for the total amount of your
          purchase, including any applicable taxes and other charges. You authorize us to use a
          third-party service to update your designated payment information if it is cancelled or
          expires. If the payment method cannot be verified, is invalid or is otherwise not
          acceptable to us, your order may be suspended or cancelled.
        </p>
        <p>
          C. Taxes.&nbsp;You are responsible for any sales, duty or other governmental taxes or fees
          due with respect to your purchase of Paid Services. We will collect applicable sales tax
          if we determine that we have a duty to collect sales tax, and will provide notice of such
          taxes at the time you place your order.
        </p>
        <p>
          D. No Refunds.&nbsp;Except as provided in Section 5(H), or as otherwise expressly agreed
          upon by The PIE Network, all sales of Paid Services (including any Subscription Services)
          are final and there are no refunds. THERE ARE NO REFUNDS OR CREDITS FOR PARTIALLY USED
          SUBSCRIPTION SERVICES PERIODS.
        </p>
        <p>
          E. Errors.&nbsp;In the event of an error in connection with the pricing or charging of
          Paid Services, we reserve the right to correct such error and revise your order
          accordingly (including charging the correct price) or to cancel the purchase and refund
          any amount charged. Your sole remedy in the event of a billing error is to obtain a refund
          for the excess amount charged. To be eligible for such refund, you must provide notice of
          any such error within 120 days of the date of the billing statement in which such error
          first appeared.
        </p>
        <p>5. Discontinuance of The PIE Network Service</p>
        <p>
          The PIE Network may, in its sole discretion and without liability to you, modify,
          discontinue, terminate, suspend or shut-down (temporarily or permanently) all or any
          portion of The PIE Network Service at any time, without prior notice. Upon any such action
          by The PIE Network, you must immediately stop using The PIE Network Service. You may also
          cancel your Account at any time, as described in Section 15 below.&nbsp;
        </p>
        <p>6. Acceptable Use</p>
        <p>
          When accessing or using The PIE Network Service, you agree that you will not violate any
          law, contract, intellectual property or other third-party right or commit a tort. Without
          limiting the generality of the foregoing, you agree that you will not do, and will not
          permit any third party to do, any of the following:
        </p>
        <ul>
          <li>
            Engage in any unauthorized use of The PIE Network Service (including, without
            limitation, political campaigning, advertising, or marketing);
          </li>
          <li>
            Transmit or otherwise make available any content that: (1) you do not have the right to
            provide or transmit using The PIE Network Service, (2) may expose The PIE Network or its
            affiliates, licensors, or users to any harm or liability, or (3) is harmful, fraudulent,
            deceptive, threatening, harassing, defamatory, obscene, unlawful, untrue, or otherwise
            objectionable;
          </li>
          <li>
            Upload to, transmit, distribute, store, create, or otherwise sell or offer for sale
            anything that violates our&nbsp;<span>Prohibited Items Guidelines</span>.
          </li>
          <li>
            Transmit or otherwise make available any content that contains software viruses or any
            other computer code, files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or telecommunications equipment;
          </li>
          <li>
            Originate, send, deliver, relay or otherwise transmit unsolicited commercial email or
            other messages through The PIE Network Service;
          </li>
          <li>
            Copy any portion of The PIE Network Service or any underlying content or source code;
          </li>
          <li>
            Reverse engineer, disassemble or decompile any portion of The PIE Network Service or
            otherwise attempt to discover or re-create the source code to any software;
          </li>
          <li>
            Distribute the software or source code behind The PIE Network Service to any third
            party;
          </li>
          <li>
            Make any modification, adaptation, improvement, enhancement, translation or derivative
            work of or to any portion of The PIE Network Service;
          </li>
          <li>
            Remove, alter, or obscure any copyright or other proprietary notices of The PIE Network
            or its affiliates or licensors in any portion of The PIE Network Service;
          </li>
          <li>
            Obscure or disable any advertisements that appear on or through The PIE Network Service;
          </li>
          <li>
            Use any type of automated means, including without limitation any harvesting bot, robot,
            spider, script, crawler, scraper or other automated means or interface not provided by
            The PIE Network, to utilize The PIE Network Service or to collect or extract data;
          </li>
          <li>
            Access without authorization any networks, systems, or databases used in providing The
            PIE Network Service or any accounts associated with The PIE Network Service, or to
            access or use any information therein for any purpose;
          </li>
          <li>Attempt to probe, test, hack, or otherwise circumvent any security measures;</li>
          <li>
            Violate any requirements, policies, procedures or regulations of any network connected
            to The PIE Network Service;
          </li>
          <li>
            Use The PIE Network Service in any manner that could damage, disable, overburden, or
            otherwise impair The PIE Network Service (or the networks connected to The PIE Network
            Service);
          </li>
          <li>
            Interfere with or disrupt the use and enjoyment by others of The PIE Network Service,
            including without limitation attempting, in any manner, to obtain the password, account,
            or other security information of any other user;
          </li>
          <li>Falsely state, impersonate, or otherwise misrepresent your identity;</li>
          <li>
            Provide false information during Account creation or The PIE Network Payment Solution,
            or otherwise provide false, inaccurate or misleading information;
          </li>
          <li>
            Create more than one Account or create an Account on behalf of anyone other than
            yourself without permission;
          </li>
          <li>Use or attempt to use another user&rsquo;s Account without authorization;</li>
          <li>
            Attempt to pay for an item using The PIE Network Payment Solution with an Electronic
            Payment Method that you either do not own or are not validly authorized to use;
          </li>
          <li>
            Use The PIE Network Service in any manner to stalk, harass, invade the privacy of, or
            otherwise cause harm to, any person;
          </li>
          <li>
            Use The PIE Network Service in any manner that exposes The PIE Network to any harm or
            liability of any nature;
          </li>
          <li>
            Use The PIE Network Service to infringe or violate the intellectual property rights or
            any other rights of anyone else (including The PIE Network);
          </li>
          <li>
            Develop any third-party applications that interact with The PIE Network Service without
            The PIE Network&rsquo;s prior written consent;
          </li>
          <li>
            Use The PIE Network Service to engage in any illegal or unauthorized purpose or to
            engage in, encourage, or promote activities that are unlawful, misleading, malicious or
            discriminatory, including, but not limited to violations of these Terms, illegal
            gambling, fraud, money-laundering, or terrorist activities;
          </li>
          <li>Transfer any rights granted to you under these Terms; or</li>
          <li>
            Encourage or induce any third party to engage in any of the activities prohibited under
            this section.
          </li>
        </ul>
        <p>
          If you violate any of the foregoing, The PIE Network reserves the right to suspend or
          terminate your right to access and use The PIE Network Service immediately without notice,
          and you will have infringed The PIE Network&rsquo;s intellectual property and other
          rights, which may subject you to prosecution and damages. The PIE Network also reserves
          the right to take any remedies it deems appropriate under the circumstances if you have
          purchased or sold items that are in violation of this Section 7. The PIE Network reserves
          the right at all times to monitor, review, retain and disclose any information regarding
          your use of The PIE Network Service as necessary to satisfy any applicable law,
          regulation, legal process or governmental request. You also acknowledge and agree that The
          PIE Network is not responsible or liable for the conduct of, or your interactions with,
          any users of The PIE Network Service (whether online or offline). Your interactions with
          other users are solely between you and such users and we are not responsible or liable for
          any loss, damage, injury or harm which results from these interactions. In addition,
          enforcement of these Terms is solely in our discretion, and the absence of enforcement in
          some instances does not constitute a waiver of our right to enforce these Terms in other
          instances. These Terms do not create any private right of action on the part of any third
          party or any reasonable expectation or promise that The PIE Network Service will not
          contain any content that is prohibited by these Terms.
        </p>
        <p>7. User Content</p>
        <p>
          In the course of using The PIE Network Service, you may transmit or otherwise make
          available certain content, including information about yourself, content, messages,
          materials, data, information, text, photos, graphics, code or other items or materials
          (&ldquo;User Content&rdquo;) through interactive areas or services, such as posting items
          for sale, making an offer, private messaging, or other areas or services. User Content may
          be publicly viewable in some instances. The PIE Network reserves the right, but does not
          have the obligation, to remove, screen or edit any User Content posted, transmitted, or
          stored on The PIE Network Service at any time and for any reason without notice. You will
          not (and will not allow or authorize any third-party to) post, upload to, transmit,
          distribute, store, create, solicit, disclose, or otherwise, publish through The PIE
          Network Service any of the following:
        </p>
        <ul>
          <li>
            User Content that is, in The PIE Network&rsquo;s judgment, disrespectful or may expose
            The PIE Network, Users or others to harm or liability;
          </li>
          <li>
            User Content that may infringe the patent, trademark, trade secret, copyright,
            intellectual, privacy or proprietary right of any party;
          </li>
          <li>
            Private information of any third parties, including addresses, phone numbers and payment
            card information;
          </li>
          <li>Viruses, corrupted data or other harmful, disruptive, or destructive files; or</li>
          <li>
            User Content that inhibits any other person from using or enjoying The PIE Network
            Service.
          </li>
        </ul>
        <p>
          You are solely responsible for creating backup copies of and replacing your User Content
          at your sole cost and expense. You acknowledge and agree that The PIE Network is not
          responsible for any errors or omissions that you make in connection with The PIE Network
          Service. By submitting or posting User Content, you hereby grant to The PIE Network a
          non-exclusive, transferable, royalty-free, perpetual, irrevocable, sublicensable right to
          use, reproduce, modify, adapt, publish, translate, sell, create derivative works from,
          distribute, perform, and display the User Content, and your name, company name, location
          and any other information you submit with the User Content, in connection with The PIE
          Network Service. The use of your or any other User&rsquo;s name, likeness, or identity in
          connection with The PIE Network Service does not imply any endorsement thereof unless
          explicitly stated otherwise. We also have the right to disclose your identity to any third
          party who is claiming that any content posted by you constitutes a violation of their
          intellectual property rights, or of their right to privacy.
        </p>
        <p>8. Moderation</p>
        <p>
          You agree that The PIE Network may moderate access to and use of The PIE Network Service
          in our sole discretion through any means (including, for example, blocking, filtering,
          deletion, delay, omission, verification, and/or termination of your access. Furthermore,
          we have the right to remove any posting you make on The PIE Network Service if, in our
          opinion, your post does not comply with the content standards set out in Sections 7 and 8
          above, and any other The PIE Network Service rules, including without limitation the&nbsp;
          <span>Prohibited Items Guidelines</span>. You agree not to bypass or attempt to bypass
          such moderation. You further agree that The PIE Network is not liable for moderating, not
          moderating or making any representations regarding moderating.
        </p>
        <p>9. Third Party Services and Content</p>
        <p>
          In using The PIE Network Service, you may view content, utilize services, or otherwise
          interact with content and services provided by third parties, including, but not limited
          to, The PIE Network Payment Solution provided by a third-party payment processor, The PIE
          Network fulfillment solution through third-party logistics providers, links and/or
          connections to websites, applications or services of such parties (&ldquo;Third-Party
          Content&rdquo;). The PIE Network does not control, endorse or adopt any Third-Party
          Content and you acknowledge and agree that The PIE Network will have no responsibility for
          any Third Party Content, including without limitation, material that may be misleading,
          incomplete, erroneous, offensive, indecent or otherwise objectionable. In addition, your
          business or other dealings or correspondence with such third parties are solely between
          you and the third parties. The PIE Network is not responsible or liable for any damage or
          loss of any sort caused, or alleged to be caused, by or in connection with any such
          dealings, including the delivery, quality, safety, legality or any other aspect of any
          good or services that you may purchase or sell to or from a third party.
        </p>
        <p>10. Feedback</p>
        <p>
          Any comments or materials sent to us, including, but not limited to, ideas, questions,
          comments, suggestions, feedback or the like regarding The PIE Network Service or any other
          products or services of The PIE Network (collectively, "Feedback"), is non-confidential
          and will become our sole property. We will have no obligation to you of any kind, monetary
          or non-monetary, with respect to such Feedback and will be free to reproduce, use,
          disclose, exhibit, display, transform, create derivative works from and distribute the
          Feedback to others without limitation or obligation. You waive any rights you may have to
          the Feedback (including any copyrights or moral rights). Further, you agree not to submit
          any feedback that is defamatory, illegal, offensive or otherwise violates any right of any
          third party, or breaches any agreement between you and any third party.
        </p>
        <p>11. Copyright Policy</p>
        <p>
          A. Repeat Infringer Policy.&nbsp;In accordance with the Digital Millennium Copyright Act
          (&ldquo;DMCA&rdquo;) and other applicable law, The PIE Network has adopted a policy of
          terminating, in appropriate circumstances and at The PIE Network&rsquo;s discretion, users
          who are deemed to be repeat infringers. We also may, at The PIE Network&rsquo;s
          discretion, limit access to The PIE Network Service and terminate access of any users who
          infringe any intellectual property rights of others, whether or not there is any repeat
          infringement.
        </p>
        <p>
          B. Copyright Complaints.&nbsp;If you believe that anything on The PIE Network Service
          infringes upon any copyright that you own or control, you may file a notification with The
          PIE Network&nbsp;
        </p>
        <p>
          Please see 17 U.S.C. &sect; 512(c)(3) for the requirements of a proper notification. If
          you knowingly misrepresent in your notification that the material or activity is
          infringing, you may be liable for any damages, including costs and attorneys&rsquo; fees,
          incurred by The PIE Network or the alleged infringer as the result of The PIE
          Network&rsquo;s reliance upon such misrepresentation in removing or disabling access to
          the material or activity claimed to be infringing.
        </p>
        <p>12. Intellectual Property Rights</p>
        <p>
          Unless otherwise indicated, The PIE Network Service and all content, materials,
          information, functionality and other materials displayed, performed, contained or
          available on or through The PIE Network Service, including, without limitation, The PIE
          Network logo, and all designs, text, graphics, pictures, information, data, sound files,
          images, illustrations, software, other files, and the selection and arrangement thereof
          (collectively, the &ldquo;Materials&rdquo;) are the proprietary property of The PIE
          Network or its affiliates or licensors, and are protected by U.S. and international
          copyright laws and other intellectual property rights laws.
        </p>
        <p>
          Except as otherwise provided, subject to your compliance with all of the terms and
          conditions of these Terms, and in consideration of your promises reflected herein (and
          with respect to any services requiring payment of fees, your payment of such fees), we
          grant to you a revocable, personal, non-exclusive, non-assignable and non-transferable
          license for personal, non-commercial purposes, except where explicitly provided otherwise,
          to (i) access and use The PIE Network Service, (ii) cause the Materials to be displayed
          from a computer and/or mobile device and (iii) use the Materials, solely as permitted
          under these Terms (the &ldquo;License&rdquo;). The PIE Network and its affiliates and
          licensors reserve all rights not expressly granted to you in these Terms. You agree that
          these Terms do not grant you any rights in or licenses to The PIE Network Service or the
          Materials, except for this express, limited License. You will not otherwise copy,
          transmit, distribute, sell, resell, license, de-compile, reverse engineer, disassemble,
          modify, publish, participate in the transfer or sale of, create derivative works from,
          perform, display, incorporate into another website, or in any other way exploit any of the
          Materials or any other part of The PIE Network Service or any derivative works thereof, in
          whole or in part for commercial or non-commercial purposes. Without limiting the
          foregoing, you agree not to frame or display The PIE Network Service or Materials (or any
          portion thereof) as part of any other website or any other work of authorship without our
          prior written permission. The License granted under this Section will automatically
          terminate if we suspend or terminate your access to The PIE Network Service.
        </p>
        <p>13. Trademarks</p>
        <p>
          The PIE Network, ThePIENetworkapp.com and other The PIE Network graphics, logos, page
          headers, buttons, icons, scripts, and service names are trademarks, registered trademarks,
          or trade dress of The PIE Network or its affiliates in the U.S. and/or other countries,
          and may not be copied, imitated, or used, in whole or in part, without The PIE
          Network&rsquo;s prior written consent. You will not use any trademark, product or service
          name of The PIE Network without our prior written permission, including without limitation
          any metatags or other &ldquo;hidden text&rdquo; utilizing any trademark, product of
          service name of The PIE Network. All other registered trademarks and service marks are
          used for reference purposes only, and remain the property of their respective owners.
          Reference to any products, services, processes or other information, by name, trademark,
          manufacturer, supplier or otherwise does not constitute or imply an endorsement,
          sponsorship or recommendation by The PIE Network.
        </p>
        <p>14. Suspension; Termination.</p>
        <p>
          The PIE Network may revoke or terminate your License to access or use The PIE Network
          Service for any reason without notice at The PIE Network&rsquo;s sole discretion. Without
          limiting the generality of the foregoing, we may revoke or terminate the License if you:
          (i) breach any obligation in these Terms or in any other agreement between you and us,
          (ii) violate any policy or guideline applicable to The PIE Network Service or Materials,
          or any other The PIE Network product or service, or (iii) use The PIE Network Service or
          the Materials other than as specifically authorized in these Terms, without our prior
          written permission. You will stop accessing or using The PIE Network Service immediately
          if The PIE Network suspends or terminates your License to access or use The PIE Network
          Service. The PIE Network reserves the right, but does not undertake any duty, to take
          appropriate legal action including the pursuit of civil, criminal, or injunctive redress
          against you for continuing to use The PIE Network Service during suspension or after
          termination. The PIE Network may recover its reasonable attorneys&rsquo; fees and court
          costs from you for such action. These Terms will remain enforceable against you while your
          License to access or use The PIE Network Service is suspended and after it is terminated.
        </p>
        <p>
          You may also terminate your License to access or use The PIE Network Service by closing
          your Account at any time. For more details on how to close your Account, please refer to
          our&nbsp;FAQs.
        </p>
        <p>15. Disclaimer of Warranties</p>
        <p>
          A.&nbsp;TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, AND EXCEPT AS EXPRESSLY
          PROVIDED TO THE CONTRARY IN A WRITING BY US, THE PIE NETWORK SERVICE, THE MATERIALS, THE
          PAID SERVICES, AND ANY ITEMS SOLD BY USERS THROUGH THE PIE NETWORK SERVICE ARE PROVIDED ON
          AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. TO THE MAXIMUM EXTENT
          PERMITTED UNDER APPLICABLE LAW, WE EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL WARRANTIES OF
          ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE
          PIE NETWORK SERVICE, INCLUDING THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN,
          AND ANY ITEMS SOLD THROUGH THE PIE NETWORK SERVICE.
        </p>
        <p>
          B.&nbsp;WITHOUT LIMITING THE FOREGOING, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE
          LAW, NEITHER THE PIE NETWORK NOR ANY OF ITS AFFILIATES OR LICENSORS, NOR THE RESPECTIVE
          OFFICERS, DIRECTORS, LICENSORS, EMPLOYEES OR REPRESENTATIVES OF SUCH PARTIES
          (COLLECTIVELY, THE "THE PIE NETWORK PROVIDERS") REPRESENT OR WARRANT (I) THAT THE PIE
          NETWORK SERVICE, MATERIALS OR ITEMS SOLD THROUGH THE PIE NETWORK SERVICE WILL MEET YOUR
          REQUIREMENTS OR BE ACCURATE, COMPLETE, APPROPRIATE, RELIABLE OR ERROR FREE; (II) THAT THE
          PIE NETWORK SERVICE, MATERIALS OR ITEMS SOLD THROUGH THE PIE NETWORK SERVICE WILL ALWAYS
          BE AVAILABLE OR WILL BE UNINTERRUPTED, ACCESSIBLE, TIMELY, RESPONSIVE OR SECURE; (III)
          THAT ANY DEFECTS WILL BE CORRECTED, OR THAT THE PIE NETWORK SERVICE, MATERIALS OR ITEMS
          SOLD THROUGH THE PIE NETWORK SERVICE WILL BE FREE FROM VIRUSES, "WORMS," "TROJAN HORSES"
          OR OTHER HARMFUL PROPERTIES; (IV) THE ACCURACY, RELIABILITY, TIMELINESS OR COMPLETENESS OF
          ANY MATERIALS AVAILABLE ON OR THROUGH THE PIE NETWORK SERVICE; (V) ANY IMPLIED WARRANTY
          ARISING FROM COURSE OF DEALING OR USAGE OF TRADE; (VI) THAT THE PIE NETWORK SERVICE,
          MATERIALS OR ITEMS SOLD THROUGH THE PIE NETWORK SERVICE ARE NON-INFRINGING; OR (VII) THAT
          ANY BADGE IS A REPRESENTATION AS TO THE IDENTITY, CHARACTER OR INTEGRITY OF ANY PERSON,
          BUSINESS, OR ENTITY THAT IS ASSOCIATED WITH A BADGE.
        </p>
        <p>
          C.&nbsp;YOU ACKNOWLEDGE THAT INFORMATION YOU STORE OR TRANSFER THROUGH THE PIE NETWORK
          SERVICE MAY BECOME IRRETRIEVABLY LOST OR CORRUPTED OR TEMPORARILY UNAVAILABLE DUE TO A
          VARIETY OF CAUSES, INCLUDING SOFTWARE FAILURES, PROTOCOL CHANGES BY THIRD PARTY PROVIDERS,
          INTERNET OUTAGES, DISASTERS, SCHEDULED OR UNSCHEDULED MAINTENANCE, OR OTHER CAUSES OUTSIDE
          OF OUR REASONABLE CONTROL. YOU ARE SOLELY RESPONSIBLE FOR BACKING UP AND MAINTAINING
          DUPLICATE COPIES OF ANY INFORMATION YOU STORE OR TRANSFER THROUGH THE PIE NETWORK SERVICE.
        </p>
        <p>
          Some jurisdictions do not allow the disclaimer of implied terms in contracts with
          consumers, so some or all of the disclaimers in this section may not apply to you.
        </p>
        <p>16. Assumption of Risk</p>
        <p>
          A. YOU ACKNOWLEDGE AND AGREE THAT THERE ARE RISKS ASSOCIATED WITH UTILIZING AN
          INTERNET-BASED MARKETPLACE AND INTERACTING WITH OTHER USERS IN PERSON. WE DO NOT
          INVESTIGATE OR VERIFY ANY USER&rsquo;S REPUTATION, CONDUCT, MORALITY, CRIMINAL BACKGROUND,
          OR ANY INFORMATION USERS MAY SUBMIT TO THE SERVICES. YOU ARE SOLELY RESPONSIBLE FOR TAKING
          ALL NECESSARY PRECAUTIONS WHEN INTERACTING WITH OTHER USERS, PARTICULARLY WHEN MEETING A
          STRANGER IN PERSON FOR THE FIRST TIME. IT IS POSSIBLE THAT OTHER USERS MAY ATTEMPT TO
          PHYSICALLY HARM OR DEFRAUD YOU OR OBTAIN INFORMATION FROM YOU FOR FRAUDULENT PURPOSES. YOU
          ARE SOLELY RESPONSIBLE FOR, AND ASSUME ALL RISKS RELATED TO, SELLING AND BUYING THROUGH
          THE PIE NETWORK&rsquo;S SERVICES (INCLUDING ALL ONLINE AND OFFLINE INTERACTIONS WITH OTHER
          USERS).
        </p>
        <p>17. Limitation of Liability</p>
        <p>
          A.&nbsp;TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE PIE
          NETWORK OR THE PIE NETWORK PROVIDERS BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR
          CONSEQUENTIAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS
          OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE),
          OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH: (I) USE OF THE PIE NETWORK
          SERVICE OR MATERIALS, INCLUDING, BUT NOT LIMITED TO, ANY DAMAGE CAUSED BY ANY RELIANCE ON,
          OR ANY DELAYS, INACCURACIES, ERRORS OR OMISSIONS IN, ANY OF THE PIE NETWORK SERVICE OR
          MATERIALS, (II) ANY INABILITY TO USE THE PIE NETWORK SERVICE OR MATERIALS FOR WHATEVER
          REASON, OR (III) ANY GOODS OR SERVICES DISCUSSED, PURCHASED OR OBTAINED, DIRECTLY OR
          INDIRECTLY, THROUGH THE PIE NETWORK SERVICE, EVEN IF THE PIE NETWORK OR THE PIE NETWORK
          PROVIDERS ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING WITHOUT LIMITATION ANY
          DAMAGES CAUSED BY OR RESULTING FROM (Y) RELIANCE BY ANY USER ON ANY INFORMATION OBTAINED
          FROM COMPANY, OR (Z) THAT RESULT FROM EVENTS BEYOND THE PIE NETWORK&rsquo;S OR THE PIE
          NETWORK PROVIDERS' REASONABLE CONTROL, SUCH AS SITE INTERRUPTIONS, DELETION OF FILES OR
          EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF
          PERFORMANCE, WHETHER OR NOT RESULTING FROM A FORCE MAJEURE EVENT, COMMUNICATIONS FAILURE,
          THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO THE PIE NETWORK&rsquo;S RECORDS, PROGRAMS OR
          SERVICES.
        </p>
        <p>
          B.&nbsp;TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE AGGREGATE
          LIABILITY OF THE PIE NETWORK OR THE PIE NETWORK PROVIDERS (JOINTLY), ARISING OUT OF OR
          RELATING TO THE USE OF, OR INABILITY TO USE THE OFFER UP SERVICE OR OTHERWISE RELATING TO
          THESE TERMS EXCEED THE GREATER OF (I) ANY COMPENSATION YOU PAY, IF ANY, TO THE PIE NETWORK
          FOR ACCESS TO OR USE OF THE PIE NETWORK SERVICE OR MATERIALS DURING THE 12 MONTHS
          PRECEDING THE EVENT GIVING RISE TO THE LIABILITY; OR (II) $100 U.S. DOLLARS.
        </p>
        <p>
          C. THE LIMITATIONS SET FORTH IN THIS SECTION 18 WILL NOT LIMIT OR EXCLUDE LIABILITY FOR
          THE PIE NETWORK OR THE PIE NETWORK PROVIDERS' GROSS NEGLIGENCE, INTENTIONAL, WILLFUL,
          MALICIOUS OR RECKLESS MISCONDUCT OR FRAUD.
        </p>
        <p>18. Indemnity</p>
        <p>
          You agree to hold harmless, defend and indemnify The PIE Network and The PIE Network
          Providers from all liabilities, losses, damages, deficiencies, claims, causes of action,
          demands and expenses, (including, but not limited to, reasonable attorneys' fees), that
          are due to, arise from or otherwise relate to your conduct or your use or misuse of The
          PIE Network Service or Materials, including, without limitation, any actual or threatened
          suit, demand or claim made against The PIE Network or any The PIE Network Provider that
          arises out of or relates to: (i) any intellectual property rights or other proprietary
          rights of any third party, (ii) your breach of these Terms including without limitation
          your breach of any of your representations and warranties; (iii) your use of any of The
          PIE Network Service or Materials; (iv) any content that you store on or transmit through
          The PIE Network Service; or (v) any items that you mail or ship in connection with The PIE
          Network Service, including items sold to other The PIE Network users. The PIE Network may
          assume exclusive control of any defense of any matter subject to indemnification by you,
          and you agree to cooperate with The PIE Network in such event.
        </p>
        <p>19. Arbitration</p>
        <p>
          PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO SUBMIT TO BINDING
          ARBITRATION (INCLUDING A JURY TRIAL WAIVER) ANY AND ALL DISPUTES (OTHER THAN SPECIFIED
          INTELLECTUAL PROPERTY CLAIMS AND SMALL CLAIMS) WITH THE PIE NETWORK AND IT LIMITS THE
          MANNER IN WHICH YOU CAN SEEK RELIEF FROM THE PIE NETWORK (NO CLASS ARBITRATIONS, CLASS
          ACTIONS OR REPRESENTATIVE ACTIONS OR ARBITRATIONS).
        </p>
        <p>
          A. Binding Arbitration.&nbsp;Except for any disputes, claims, suits, actions, causes of
          action, demands or proceedings (collectively, &ldquo;Disputes&rdquo;) arising out of or
          related to a violation of Section 7 or Disputes in which either party seeks to bring an
          individual action in small claims court or seeks injunctive or other equitable relief for
          the alleged unlawful use of intellectual property, including, without limitation,
          copyrights, trademarks, trade names, logos, trade secrets or patents, you and The PIE
          Network agree (1) to waive your and The PIE Network&rsquo;s respective rights to have any
          and all Disputes arising from or related to these Terms, The PIE Network Service or the
          Materials, resolved in a court, and (2) to waive your and The PIE Network&rsquo;s
          respective rights to a jury trial. Instead, you and The PIE Network agree to arbitrate
          Disputes through binding arbitration (which is the referral of a Dispute to one or more
          persons charged with reviewing the Dispute and making a final and binding determination to
          resolve it instead of having the Dispute decided by a judge or jury in court).
        </p>
        <p>
          B.&nbsp;No Class Arbitrations, Class Actions or Representative Actions.&nbsp;You and The
          PIE Network agree that any Dispute arising out of or related to these Terms, The PIE
          Network Service or the Materials is personal to you and The PIE Network and that such
          Dispute will be resolved solely through individual arbitration and will not be brought as
          a class arbitration, class action or any other type of representative proceeding. You and
          The PIE Network agree that there will be no class arbitration or arbitration in which an
          individual attempts to resolve a Dispute as a representative of another individual or
          group of individuals. Further, you and The PIE Network agree that a Dispute cannot be
          brought as a class or other type of representative action, whether within or outside of
          arbitration, or on behalf of any other individual or group of individuals. The limitations
          imposed by this Section 20(B) shall apply to, but are in no way limited to, class action
          refund claims brought by a class of taxpayers against The PIE Network related to taxes
          collected and remitted in good faith efforts at compliance with state and local
          marketplace facilitator or marketplace provider laws. Nothing in this Section 20(B) shall
          be construed to prohibit a buyer from filing a refund claim with the seller or applicable
          state and local tax authority as permitted by law.
        </p>
        <p>
          C.&nbsp;Federal Arbitration Act.&nbsp;You and The PIE Network agree that these Terms
          affect interstate commerce and that the enforceability of this Section 20 shall be both
          substantively and procedurally governed by and construed and enforced in accordance with
          the Federal Arbitration Act, 9 U.S.C. &sect; 1 et seq. (the &ldquo;FAA&rdquo;), to the
          maximum extent permitted by applicable law.
        </p>
        <p>
          D.&nbsp;Notice; Informal Dispute Resolution.&nbsp;You and The PIE Network agree that each
          party will notify the other party in writing of any arbitrable or small claims Dispute
          within thirty (30) days of the date it arises, so that the parties can attempt in good
          faith to resolve the Dispute informally. Notice to The PIE Network shall be sent by
          certified mail or courier to The PIE Network, Inc., Attn: The PIE Network Designated Agent
          1201 Orange Street, Suite 600, Wilmington, New Castle County, Delaware 19801. Your notice
          must include (1) your name, postal address, telephone number, the email address you use or
          used for your Account, and, if different, an email address at which you can be contacted,
          (2) a description in reasonable detail of the nature or basis of the Dispute, and (3) the
          specific relief that you are seeking. Our notice to you will be sent to the email address
          you used to register for your Account, and will include (a) our name, postal address,
          telephone number and an email address at which we can be contacted with respect to the
          Dispute, (b) a description in reasonable detail of the nature or basis of the Dispute, and
          (c) the specific relief that we are seeking. If you and The PIE Network cannot agree how
          to resolve the Dispute within thirty (30) days after the date notice is received by the
          applicable party, then either you or The PIE Network may, as appropriate and in accordance
          with this Section 20, commence an arbitration proceeding, or to the extent specifically
          provided for in section 20(A), file a claim in court.
        </p>
        <p>
          E.&nbsp;Process.&nbsp;Except for Disputes arising out of or related to a violation of
          Section 7 or Disputes in which either party seeks to bring an individual action in small
          claims court or seeks injunctive or other equitable relief for the alleged unlawful use of
          intellectual property, including without limitation, copyrights, trademarks, trade names,
          logos, trade secrets or patents, you and The PIE Network agree that any Dispute must be
          commenced or filed by you or The PIE Network within one (1) year of the date the Dispute
          arose, otherwise the underlying claim is permanently barred (which means that you and The
          PIE Network will no longer have the right to assert such claim regarding the Dispute). You
          and The PIE Network agree that (1) any arbitration will occur in Delaware, (2) arbitration
          will be conducted confidentially by a single arbitrator in accordance with the rules of
          the Judicial Arbitration and mediation Services (&ldquo;JAMS&rdquo;), which are hereby
          incorporated by reference, and (3) that the state or federal courts of the State of
          Delaware and the United States, respectively, sitting in Delaware, have exclusive
          jurisdiction over any appeals and the enforcement of an arbitration award. You may also
          litigate a Dispute in the small claims court located in the county of your billing address
          if the Dispute meets the requirements to be heard in small claims court.
        </p>
        <p>
          F.&nbsp;Authority of the Arbitrator.&nbsp;As limited by the FAA, these Terms and the
          applicable JAMS rules, the arbitrator will have (1) the exclusive authority and
          jurisdiction to make all procedural and substantive decisions regarding a Dispute,
          including the determination of whether a Dispute is arbitrable, and (2) the authority to
          grant any remedy that would otherwise be available in court; provided, however, that the
          arbitrator does not have the authority to conduct a class arbitration or a representative
          action, which is prohibited by these Terms. The arbitrator may only conduct an individual
          arbitration and may not consolidate more than one individual&rsquo;s claims, preside over
          any type of class or representative proceeding or preside over any proceeding involving
          more than one individual.
        </p>
        <p>
          G.&nbsp;Severability.&nbsp;If any term, clause or provision of this Section 20 is held
          invalid or unenforceable, it will be so held to the minimum extent required by law, and
          all other terms, clauses and provisions of this Section 20 will remain valid and
          enforceable. Further, the waivers set forth in Section 20(B) are severable from the other
          provisions of these Terms and will remain valid and enforceable, except as prohibited by
          applicable law.
        </p>
        <p>
          I.&nbsp;Opt-Out Right.&nbsp;You have the right to opt out of binding arbitration within
          thirty (30) days of the date you first accepted the terms of this Section 20 by writing
          to: support@thepienetworkapp.com. In order to be effective, the opt-out notice must
          include your full name and clearly indicate your intent to opt-out of binding arbitration.
          By opting out of binding arbitration, you are agreeing to resolve Disputes in accordance
          with Section 21.
        </p>
        <p>20. Governing Law; Venue.</p>
        <p>
          These Terms, your access to and use of The PIE Network Service and Materials shall be
          governed by and construed and enforced in accordance with the laws of the State of
          Delaware without regard to conflict of law rules or principles (whether of the State of
          Delaware or any other jurisdiction) that would cause the application of the laws of any
          other jurisdiction. Any Dispute between the parties that is not subject to arbitration or
          cannot be heard in small claims court, shall be resolved in the state or federal courts of
          the State of Delaware and the United States, respectively, sitting in New Castle County,
          Delaware.
        </p>
        <p>21. Miscellaneous</p>
        <p>
          A.&nbsp;Entire Agreement; Order of Precedence.&nbsp;These Terms contain the entire
          agreement, and supersede all prior and contemporaneous understandings between the parties
          regarding The PIE Network Service. These Terms do not alter the terms or conditions of any
          other electronic or written agreement you may have with The PIE Network for The PIE
          Network Service or for any other The PIE Network product, feature, service or otherwise.
          In the event of any conflict between these Terms and any other agreement you may have with
          The PIE Network, the terms of that other agreement will control only if these Terms are
          specifically identified and declared to be overridden by such other agreement.
        </p>
        <p>
          B.&nbsp;Amendments.&nbsp;We reserve the right to make changes or modifications to these
          Terms from time to time, in our sole discretion. If we make changes to these Terms, we
          will provide you with notice of such changes, such as by sending you an email and/or by
          posting the amended Terms via The PIE Network Service and updating the &ldquo;Last
          Updated&rdquo; date at the top of these Terms. All amended Terms will become effective
          immediately on the date they are posted to The PIE Network Service unless we state
          otherwise via our notice of such amended Terms. Any amended Terms will apply prospectively
          to use of The PIE Network Service after such changes become effective. Your continued use
          of The PIE Network Service following the effective date of such changes will constitute
          your acceptance of such changes. If you do not agree to any amended Terms, you must
          discontinue using The PIE Network Service.
        </p>
        <p>
          C.&nbsp;Severability.&nbsp;If any provision of these Terms is held to be unenforceable for
          any reason, such provision will be reformed only to the extent necessary to make it
          enforceable, and such decision will not affect the enforceability of such provision under
          other circumstances, or of the remaining provisions hereof under all circumstances.
        </p>
        <p>
          D.&nbsp;Waiver.&nbsp;Our failure or delay in exercising any right, power or privilege
          under these Terms will not operate as a waiver thereof.
        </p>
        <p>
          E.&nbsp;Relationship.&nbsp;The PIE Network is an independent contractor for all purposes,
          and is not your agent or trustee. You are not an agent of The PIE Network.
        </p>
        <p>
          F.&nbsp;Assignment.&nbsp;You may not assign or transfer any of your rights or obligations
          under these Terms without prior written consent from The PIE Network, including by
          operation of law or in connection with any change of control. The PIE Network may assign
          or transfer any or all of its rights under these Terms, in whole or in part, without
          obtaining your consent or approval.
        </p>
        <p>
          G.&nbsp;Headings.&nbsp;Headings of sections are for convenience only and will not be used
          to limit or construe such sections.
        </p>
        <p>
          H.&nbsp;Survival.&nbsp;Sections 16 (Disclaimer of Warranties), 18 (Limitation of
          Liability), 19 (Indemnity), 20 (Arbitration), 21 (Governing Law; Venue), this Section 22
          (Miscellaneous), and any other term that, by its nature, should survive, will survive any
          termination or expiration of these Terms.
        </p>
        <p>
          <strong>Buyer Protection Policy</strong>
        </p>
        <p>
          As a buyer, you have the ability to file a claim for any shipped purchase. Make sure to
          request a refund before filing a claim, as this may be a faster solution to your issue.
        </p>
        <p>
          If you&rsquo;re unable to request a refund from the seller successfully, you may file a
          claim. You will have to check to make sure whether you purchased your item from a Verified
          Shop or community seller, as the process and timeframe for each are different. Keep in
          mind that certain restrictions will apply to both types of purchases.
        </p>
        <p>
          <strong>What is covered:</strong>
        </p>
        <p>
          The following issues are covered with purchase protection when bought and shipped through
          The PIE Network:
        </p>
        <ul>
          <li>The buyer did not receive the item</li>
          <li>The item is damaged when the buyer receives it</li>
          <li>The item is incorrect or missing</li>
          <li>The item the buyer receives was not as advertised</li>
          <li>The Seller did not show up for the service</li>
          <li>
            The Seller did not show up with an adequate amount of time to perform service purchased
          </li>
          <li>The service provided was not as advertised</li>
        </ul>
        <p>
          <strong>Note:</strong>&nbsp;If the&nbsp;<strong>item isn&rsquo;t shipped</strong>
          &nbsp;within 5 days of payment, the buyer's payment is automatically canceled and returned
          to the account they used to buy the item.
        </p>
        <p>
          <strong>What isn't covered:</strong>
        </p>
        <ul>
          <li>An item doesn't fit or the buyer doesn't like it</li>
          <li>An item is prohibited by The PIE Network</li>
          <li>The purchase was made in cash or with an in-person cashless payment</li>
          <li>
            The purchase was conducted through payment or shipping systems outside of The PIE
            Network
          </li>
        </ul>
        <p>Refund Request</p>
        <ul>
          <li>
            You have 48 hours from completion of service/delivery of product to file a refund
            request
          </li>
          <li>
            Send an email to <span>support@pienet.net</span> stating the reason for your claim and
            your request will be reviewed by The PIE Network and responded to within 24 hours
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>Prohibited items guidelines</p>
        <p>
          Although most things can be sold on The PIE Network, certain items aren't allowed. Some
          present legal risks or may pose health and safety issues. We've also chosen not to allow
          items that we feel are inappropriate for our community or could be considered offensive.
        </p>
        <p>
          Before posting your items, please read the guidelines below to ensure that they are OK to
          sell on The PIE Network. Also please review our&nbsp;<span>Posting rules</span>
          &nbsp;and&nbsp;<span>Community guidelines</span>.&nbsp;The PIE Network may make exceptions
          to these rules for certain verified sellers in its sole discretion.
        </p>
        <p>The following items are not allowed for sale:</p>
        <ul>
          <li>Alcohol, drugs&nbsp;&amp; tobacco</li>
          <li>Adult &amp; mature content</li>
          <li>Animals</li>
          <li>Wildlife products</li>
          <li>Counterfeit and replica items</li>
          <li>Dangerous items</li>
          <li>Recalled items</li>
          <li>Food items</li>
          <li>Gift cards</li>
          <li>Illegal items or encouraging illegal activity</li>
          <li>Intangible items</li>
          <li>Medical &amp; healthcare items</li>
          <li>Offensive materials</li>
        </ul>
        <p>&nbsp;</p>
        <p>Alcohol, drugs&nbsp;&amp; tobacco</p>
        <p>
          Alcohol, drugs, tobacco, and related products are often subject to regulatory restrictions
          and are illegal for sale in some cases. Because we can't enforce these laws and
          regulations, we don't allow these items for sale, including:
        </p>
        <ul>
          <li>Alcohol</li>
          <li>Prescription drugs</li>
          <li>
            Drugs, narcotics, or controlled substances (even those that are legal in some states)
          </li>
          <li>Items marketed for the purpose of intoxication</li>
          <li>Drug paraphernalia such as bongs, vaporizers, and pipes</li>
          <li>
            Tobacco and related products, including e-cigarettes, e-juice, vaporizers, mods,
            hookahs, and hookah accessories
          </li>
          <li>Cannabis and cannabis-derived products, such as CBD</li>
          <li>Growing or cultivation equipment for&nbsp;cannabis and cannabis-derived products</li>
        </ul>
        <p>&nbsp;</p>
        <p>Adult &amp; mature content</p>
        <p>
          The PIE Network strives to be an open marketplace but understands that not all people want
          to see explicit sexual content. Items intended for adult use or that contain explicit
          sexual content are not allowed on The PIE Network, such as:
        </p>
        <ul>
          <li>Explicit sexual content or nudity</li>
          <li>Pornography</li>
          <li>Adult toys</li>
        </ul>
        <p>&nbsp;</p>
        <p>Animals</p>
        <p>
          Our policy around animals and wildlife products represents The PIE Network&rsquo;s
          commitment to improving animal welfare in our own communities and in helping to protect
          threatened and endangered animals around the world. &nbsp;&nbsp;
        </p>
        <p>
          You can certainly post many types of animal products. However, live animals are never
          allowed, including:
        </p>
        <ul>
          <li>Pets</li>
          <li>Farm animals and livestock</li>
          <li>Insects</li>
          <li>Marine life</li>
        </ul>
        <p>
          For more information, visit our detailed list of&nbsp;<span>Animals</span>.
        </p>
        <p>&nbsp;</p>
        <p>Wildlife &amp; wildlife products</p>
        <p>
          You can certainly post many types of animal products. However, wildlife products that were
          sourced illegally or are being traded in contravention of any law are prohibited.
        </p>
        <p>
          For more information, visit our detailed list of&nbsp;
          <span>Wildlife &amp; wildlife products</span>.
        </p>
        <p>Counterfeit or replica items</p>
        <p>
          The sale of bootlegs, counterfeits, fakes, and unauthorized or pirated copies of items is
          illegal and not allowed on The PIE Network, including:
        </p>
        <ul>
          <li>
            Counterfeits, fakes, and replicas of brand-name items, including items
            &ldquo;inspired&rdquo; by a brand without permission of the owner
          </li>
          <li>Bootlegged or unauthorized recordings</li>
          <li>Pirated copies of any copyrighted materials</li>
          <li>Use of a trademark or other implied affiliation with a brand</li>
        </ul>
        <p>Shipping and counterfeit items</p>
        <p>
          For more information, visit our&nbsp;<span>Intellectual property policy</span>.
        </p>
        <p>&nbsp;</p>
        <p>Dangerous items</p>
        <p>
          Items that pose health and safety concerns or that are regulated or illegal are not
          allowed on The PIE Network. These include but are not limited to:
        </p>
        <ul>
          <li>Firearms, no exceptions</li>
          <li>
            Knives primarily used as weapons or for self-defense.&nbsp;Note: Cutlery, kitchen, and
            utility knives are generally permitted
          </li>
          <li>
            Mace, pepper spray, bear spray, tasers, and other weapons used to subdue humans or
            animals
          </li>
          <li>Batons, billyclubs, or similar weapons used for striking a person or animal</li>
          <li>
            Paintball guns, BB guns, guns that fire blanks, Airsoft Guns, and all related ammunition
            and accessories
          </li>
          <li>Sporting weapons such as bows and arrows, crossbows, and archery items</li>
          <li>
            Swords, if sharpened or functional.&nbsp;Note: Decorative blunt swords are generally
            permitted
          </li>
          <li>
            Weapon parts and accessories, including&nbsp;any item that attaches to a
            firearm,&nbsp;ammunition, bullets, clips, arrows, and bolts
          </li>
          <li>
            Body armor, including Kevlar and other tactical and protective vests, except protective
            clothing for sporting activities
          </li>
          <li>Regulated chemicals, poisons, or substances</li>
          <li>New or used airbags&nbsp;sold separately from a vehicle</li>
          <li>Hazardous materials such as:</li>
          <ul>
            <li>Fireworks</li>
            <li>Explosives</li>
          </ul>
          <li>Items deemed to pose significant health or safety risks to others</li>
          <li>Items used to incite violence</li>
          <li>Recalled items (see below)</li>
        </ul>
        <p>&nbsp;</p>
        <p>Recalled items</p>
        <p>
          Items that are subject to a recall, typically because they are deemed unsafe or defective
          by federal regulators, are not allowed on The PIE Network. These items present a unique
          risk because the harm they present is often not visibly apparent.&nbsp; Items recalled in
          the past include:
        </p>
        <ul>
          <li>Samsung Galaxy Note 7 cell phones</li>
          <li>Fisher-Price Rock N&rsquo; Play infant sleepers</li>
          <li>Drop-side cribs</li>
          <li>
            <span>Other items listed&nbsp;</span>on the U.S. CPSC list of recalls
          </li>
        </ul>
        <p>
          You should avoid selling or buying any recalled item or product. If you end up selling or
          buying a recalled item, you should contact the manufacturer as they may offer a refund,
          replacement, or voucher in exchange for surrendering the recalled item. For more
          information on recalled consumer products generally, visit the&nbsp;
          <span>U.S. Consumer Product Safety Commission&rsquo;s website</span>.
        </p>
        <p>&nbsp;</p>
        <p>Food items</p>
        <p>
          The sale of homemade prepared food is subject to regulatory guidelines and often requires
          a permit. Because we can't enforce these restrictions, prepared food is not allowed.
          Examples:
        </p>
        <ul>
          <li>
            Most perishable goods, including meat, seafood, dairy, and cut fruits or vegetables
          </li>
          <li>Homemade shelf products, such as jams, jellies, and pickles</li>
          <li>Homemade meals or baked goods</li>
          <li>Eggs sold outside of the seller's farm</li>
        </ul>
        <p>Exceptions to this policy include:</p>
        <ul>
          <li>Whole, uncut fruits and vegetables</li>
          <li>Eggs sold directly on the seller's farm</li>
          <li>
            Unopened and packaged commercially sold food goods (canned soup, packages of cookies,
            etc.) as long as the packaging has not been tampered with and the items are sold before
            their expiration date
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>Gift cards</p>
        <p>
          Gift cards and reloadable cards are not allowed due to the risk for buyers. The balance
          can't always be verified, and sometimes the card balance can be used or cancelled by the
          original purchaser.
        </p>
        <p>&nbsp;</p>
        <p>Illegal items or encouraging illegal activity</p>
        <p>
          Any item that is illegal or that encourages illegal activity is not allowed, including but
          not limited to:
        </p>
        <ul>
          <li>
            Items with sensitive information such as IDs, passports, and social security cards
          </li>
          <li>
            Fake or forged documents (such as licenses, IDs, diplomas, and government-issued
            documents)
          </li>
          <li>Items received through Government Assistance</li>
          <li>Offers to trade for illegal goods</li>
          <li>
            Police badges or security uniforms, as these can be used to impersonate an officer or
            gain access to secure areas
          </li>
          <li>
            Locked phones or devices, for example blacklisted, activation, iCloud or similar locks
          </li>
          <li>Current vehicle license plates or plates that were valid in the last 5 years</li>
          <li>License plate tags</li>
          <li>TV streaming devices</li>
          <li>Cars without titles</li>
          <li>Canisters or cylinders of pressurized gas (such as CO2 or nitrous oxide)</li>
          <li>Stolen or found goods</li>
          <li>Rideshare signs</li>
        </ul>
        <p>&nbsp;</p>
        <p>Intangible&nbsp;Items</p>
        <p>
          The PIE Network is intended for the sale of individual, tangible items. Items must exist
          and be available for immediate sale. Examples of things that are not allowed include:
        </p>
        <ul>
          <li>
            Services&nbsp;for intangible items such as dog walking, babysitting, and IT support
          </li>
          <li>Advertisements</li>
          <ul>
            <li>Links to or information about flea markets, yard sales, and garage sales</li>
            <li>URLs, links, or redirecting to other websites</li>
            <li>Rentals and timeshares</li>
            <li>Multi-level marketing schemes and related promotional materials</li>
            <li>Job postings</li>
            <li>Lost pet ads</li>
          </ul>
          <li>Wanted ads</li>
          <li>Solicitations</li>
          <li>Digital account transfers</li>
          <li>Invites, referral codes, or discount codes for apps or websites</li>
          <li>
            Items planned for future purchase from another retailer, wholesaler, distributor, or
            manufacturer
          </li>
          <li>
            Items of unknown contents such as &ldquo;mystery boxes&rdquo; or &ldquo;grab bags&rdquo;
          </li>
          <li>Items where the photo does not match the title or description</li>
        </ul>
        <p>&nbsp;</p>
        <p>Medical &amp; healthcare items</p>
        <p>
          Due to health and safety, privacy, and regulatory concerns, certain medical and healthcare
          items are not allowed for sale on The PIE Network, including but not limited to:
        </p>
        <ul>
          <li>
            Devices, drugs, and medications that require a prescription from a licensed medical
            practitioner (such as a dentist, doctor, optometrist, or veterinarian)
          </li>
          <li>
            Listings that claim the item can diagnose, cure, mitigate, treat, or prevent a disease
            or viruses, including COVID-19 (the novel coronavirus)
          </li>
          <li>Ventilators and other respiratory equipment</li>
          <li>Cannabis and cannabis-derived products, such as CBD</li>
          <li>Pills, vitamins, and supplements</li>
          <li>Contact lenses</li>
          <li>Used cosmetics</li>
          <li>Used makeup sponges and applicators</li>
          <li>Needles and items containing needles (such as syringes or tattoo equipment)</li>
          <li>
            Any post related to administering vaccinations, including access to, proof of, or a
            physical item
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>Offensive materials</p>
        <p>
          The PIE Network is an inclusive community that welcomes users from all backgrounds. To
          build a respectful mobile marketplace, posts that contain foul language or that promote
          hatred, violence, or discrimination are not allowed. This includes posts that support hate
          or discrimination toward others based on age, race, ethnicity, national origin, religion,
          gender, gender identity, disability, or sexual orientation, or items that promote or
          glorify organizations with such views. Examples:
        </p>
        <ul>
          <li>Posts containing racial slurs</li>
          <li>Posts promoting intolerance or discrimination</li>
          <li>
            Items that promote, support, or commemorate groups with views of hatred or intolerance
            (for example Nazi, Neo-Nazi, KKK groups, Proud Boys, QAnon, Antifa, or the Boogaloo
            movement)
          </li>
          <li>Items that depict or glorify violence against people or animals</li>
          <li>
            Items that reflect people of color through offensive or dehumanizing caricatures,
            including historic, antique, or memorabilia items
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>Help keep our community strong</p>
        <p>
          If you see a prohibited item on The PIE Network, please report it in the app. On that
          item&rsquo;s page, tap&nbsp;&nbsp;Report, then&nbsp;Prohibited on The PIE Network,
          then&nbsp;Done. For more information on reporting users and items, please
          see&nbsp;Reporting/blocking users and items.
        </p>
        <p>
          For additional restrictions on behavior such as profanity and harassment, please
          see&nbsp;Community guidelines.
        </p>
        <p>Non-compliance</p>
        <p>
          Posts that violate any of our guidelines, policies, or terms may be removed at our
          discretion. Additional actions may be taken for severe or repeat offenses.
        </p>
        <p>&nbsp;</p>
        {/* Add more paragraphs as needed */}
      </div>
    </div>
  );
};

export default TermsConditions;
