import React from 'react';
import './styles/ContactUs.css';

const ContactUs = () => {
  return (
    <div className='contact-container'>
      <h1 className='contact-text'>Contact Us</h1>
      <p className='contact-text'>
        If you have any questions or need support, feel free to reach out to us at:
      </p>
      <a
        href='mailto:info@pienet.net'
        className='contact-email'>
        info@pienet.net
      </a>
    </div>
  );
};

export default ContactUs;
