import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { PreferencesProvider } from './context/theme'; // Adjust path as needed
import { store, persistor } from './redux/store'; // Import persistor
import App from './App';

import './index.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={<div>Loading...</div>}
        persistor={persistor}>
        <PreferencesProvider>
          <App />
        </PreferencesProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
