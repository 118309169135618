import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './screens/LandingPage';
import Login from './screens/Login';
import ChoosePOS from './screens/ChoosePOS';
import Inventory from './screens/Inventory';
import TermsConditions from './screens/TermsConditions';
import PrivacyPolicy from './screens/PrivacyPolicy';
import ContactUs from './screens/ContactUs';
import SignUp from './screens/SignUp';
import AllSet from './screens/AllSet';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        {/* Routing Setup */}
        <Routes>
          <Route
            path='/'
            element={<LandingPage />}
          />
          <Route
            path='/login'
            element={<Login />}
          />
          <Route
            path='/apply'
            element={<SignUp />}
          />
          <Route
            path='/choose-pos'
            element={<ChoosePOS />}
          />
          <Route
            path='/inventory'
            element={<Inventory />}
          />
          <Route
            path='/terms'
            element={<TermsConditions />}
          />
          <Route
            path='/privacy'
            element={<PrivacyPolicy />}
          />
          <Route
            path='/contact'
            element={<ContactUs />}
          />
          <Route
            path='/all-set'
            element={<AllSet />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
