import React, { useContext, useEffect } from 'react';
import { PreferencesContext } from '../context/theme';
import { ButtonGeneral } from '../components/buttonGeneral';
import './styles/LandingPage.css';
import logo from '../PIELogo.png';
import appStoreWhite from '../components/images/appStoreWhite.svg';
import iphoneMockup from '../components/images/iPhoneImage.png';
import { useNavigate, Link } from 'react-router-dom';
import audi from '../components/images/audiLogo.png';
import kw from '../components/images/kwLogo.png';
import tristar from '../components/images/tristarLogo.jpeg';
import ufc from '../components/images/ufcLogo.png';
import ms from '../components/images/morganStanleyLogo.png';
import { useSelector, useDispatch } from 'react-redux';
import { setProvider, setSeller } from '../redux/features/sellerSlice';
import { getSeller_ } from '../api/authApi';

const LandingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useContext(PreferencesContext);
  const seller = useSelector((state) => state.seller.seller);

  useEffect(() => {
    const fetchSellerData = async () => {
      if (seller) {
        if (Array.isArray(seller.oauthTokens) && seller.oauthTokens.length > 0) {
          const provider = seller.oauthTokens[0].provider;
          dispatch(setProvider(provider));
          navigate('/inventory');
        } else {
          try {
            // Fetch the seller data and update if oauthTokens do not exist or are empty
            const updatedSeller = await getSeller_(seller?.seller_uid);
            dispatch(setSeller(updatedSeller));
          } catch (error) {
            console.error('Failed to fetch seller data:', error);
          }
        }
      }
    };

    // Run fetchSellerData only when seller is present and oauthTokens are undefined or empty
    if (seller && (!Array.isArray(seller.oauthTokens) || seller.oauthTokens.length === 0)) {
      fetchSellerData();
    }
  }, [seller, navigate, dispatch]);

  const trustedCompanies = [
    { id: '1', title: 'Audi', imageUrl: audi },
    { id: '2', title: 'KW', imageUrl: kw },
    { id: '3', title: 'Tristar', imageUrl: tristar },
    { id: '4', title: 'UFC', imageUrl: ufc },
    { id: '5', title: 'MS', imageUrl: ms },
  ];

  return (
    <div className='landing-container'>
      <header className='landing-header'>
        <ButtonGeneral
          title='Sell On PIE'
          titleColor={theme.colors.onBackground}
          onPress={() => navigate('/apply')}
          fontSize={14}
          height={40}
          color={theme.colors.pieGreen}
          radius={20}
          margin={10}
        />
        <ButtonGeneral
          title='Seller Login'
          onPress={() => navigate('/login')}
          fontSize={14}
          height={40}
          border={true}
          radius={20}
          margin={10}
          color={theme.colors.background}
        />
      </header>

      <main className='landing-main'>
        <div className='landing-left'>
          <div className='iphone-mockup-container'>
            <img
              src={iphoneMockup}
              alt='iPhone Mockup'
              className='iphone-mockup-image'
            />
          </div>
        </div>
        <div className='landing-right'>
          <img
            src={logo}
            alt='Shop On PIE Logo'
            className='logo-image'
          />
          <p className='slogan-text'>Find local products & services you'll love.</p>
          <p className='description-text'>
            PIE is a free platform that connects you with local businesses offering great products
            and services. Download now to start exploring!
          </p>
          <a
            href='https://apps.apple.com/us/app/shop-on-pie/id6504500912'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src={appStoreWhite}
              alt='Download on the App Store'
              className='app-store-image'
            />
          </a>
        </div>
      </main>

      {/* Existing Sections */}
      {/* <section className='what-we-do-section'>
        <h2 className='section-title'>Connecting Small Businesses to Local Customers</h2>
        <p className='section-description'>
          We empower small businesses by providing a platform to advertise their products, services,
          and exclusive deals directly to local consumers. Grow your business and reach your
          community with ease.
        </p>
      </section> */}

      <section className='trusted-section'>
        <h2 className='section-title'>Founded by Former Employees of:</h2>
        <div className='logo-carousel'>
          {trustedCompanies.map((company) => (
            <img
              key={company.id}
              src={company.imageUrl}
              alt={`Trusted Partner ${company.title}`}
              className='trusted-logo'
            />
          ))}
        </div>
      </section>

      <footer className='landing-footer'>
        <Link
          to='/terms'
          className='footer-link'>
          Terms & Conditions
        </Link>
        <Link
          to='/privacy'
          className='footer-link'>
          Privacy Policy
        </Link>
        <Link
          to='/contact'
          className='footer-link'>
          Contact Us
        </Link>
      </footer>
    </div>
  );
};

export default LandingPage;
