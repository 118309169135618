import React, { createContext, useMemo } from 'react';

export const darkTheme = {
  colors: {
    background: '#ffffff',
    pieGreen: '#8be88b',
    pieBlue: '#70cfe5',
    lightGray: '#b3b3b3',
    softGray: 'rgba(240, 240, 240, 0.6)',
    disabled: '#828282',
    onBackground: '#121212',
    black: '#000000',
    error: '#ff0000',
    darkRed: '#b00020',
    roseGold: '#B76E79',
    surface: 'rgb(234, 236, 238 )',
    onSurface: 'rgb(28, 28, 28)',
    pieBlueContainer: 'rgb(209, 241, 248)',
    onPieBlueContainer: 'rgb(0, 51, 102)',
    pieGreenContainer: 'rgb(182, 247, 182)',
    onPieGreenContainer: 'rgb(39, 102, 36)',
  },
};

export const PreferencesContext = createContext({
  theme: darkTheme,
});

export const PreferencesProvider = ({ children }) => {
  const currentTheme = darkTheme;

  const preferences = useMemo(() => ({ theme: currentTheme }), [currentTheme]);

  return <PreferencesContext.Provider value={preferences}>{children}</PreferencesContext.Provider>;
};
