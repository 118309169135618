import { createSlice } from '@reduxjs/toolkit';
// import { searchProducts as SearchProductsAPI } from '@api/searchAPI';

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    searchQuery: '',
    products: [],
    sellers: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSearchResults(state, action) {
      state.products = action.payload.products;
    },
    clearSearchState(state) {
      state.searchQuery = '';
      state.products = [];
      state.sellers = [];
    },
    setSearchLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSearchError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  setSearchQuery,
  setSearchResults,
  clearSearchState,
  setSearchError,
  setSearchLoading,
} = searchSlice.actions;

// export const searchProducts = (searchQuery) => async (dispatch) => {
//   dispatch(setSearchLoading(true));
//   try {
//     const products = await SearchProductsAPI(searchQuery);
//     dispatch(setSearchResults({ products }));
//   } catch (error) {
//     dispatch(setSearchError(error.toString()));
//   } finally {
//     dispatch(setSearchLoading(false));
//   }
// };

export default searchSlice.reducer;
