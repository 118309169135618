import React from 'react';
import './styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className='privacy-container'>
      <h1 className='privacy-text'>Privacy Policy</h1>
      <div className='privacy-content'>
        <p>Last updated: October 28, 2024</p>
        <p>
          Welcome to The PIE Network (the &ldquo;Platform&rdquo;). The Platform is provided and
          controlled by The PIE Network Inc. (&ldquo;The PIE Network&rdquo;, &ldquo;we&rdquo; or
          &ldquo;us&rdquo;). We are committed to protecting and respecting your privacy. This
          Privacy Policy covers the experience we provide for users age 18 and over on our
          Platform.&nbsp;
        </p>
        <p>
          Capitalized terms that are not defined in this policy have the meaning given to them in
          the&nbsp;Terms of Service.
        </p>
        <p>
          <strong>What information do we collect?</strong>
        </p>
        <p>
          We collect information when you create an account and use the Platform. We also collect
          information and technical and behavioral information about your use of the Platform. We
          also collect information contained in the messages you send through our Platform and
          information from your phone book, if you grant us access to your phone book on your mobile
          device. More information about the categories and sources of information is provided
          below.
        </p>
        <p>
          <strong>Information you choose to provide</strong>
        </p>
        <p>
          For certain activities, such as when you register, upload content to the Platform, or
          contact us directly, you may provide some or all of the following information:
        </p>
        <ul>
          <li>
            Registration information, such as age, username and password, language, and email or
            phone number
          </li>
          <li>
            Profile information, such as name, social media account information, and profile image
          </li>
          <li>
            User-generated content, including comments, photographs, videos, and virtual item videos
            that you choose to upload or broadcast on the Platform (&ldquo;User Content&rdquo;)
          </li>
          <li>
            Payment information, such as Stripe or other third-party payment information (where
            required for the purpose of payment)
          </li>
          <li>
            Your phone and social network contacts, with your permission. If you choose to find
            other users through your phone contacts, we will access and collect the names and phone
            numbers and match that information against existing users of the Platform. If you choose
            to find other users through your social network contacts, we will collect your public
            profile information as well as names and profiles of your social contacts
          </li>
          <li>Your opt-in choices and communication preferences</li>
          <li>Information to verify an account</li>
          <li>Information in correspondence you send to us</li>
          <li>
            Information you share through surveys or your participation in challenges, sweepstakes,
            or contests such as your gender, age, likeness, and preferences.
          </li>
        </ul>
        <p>
          <strong>Information we obtain from other sources</strong>
        </p>
        <p>
          We may receive the information described in this Privacy Policy from other sources, such
          as:
        </p>
        <p>
          Others Users of the Platform. Sometimes other users of the Platform may provide us
          information about you, including through customer service inquiries.
        </p>
        <p>
          Other Sources. We may collect information about you from other publicly available sources.
        </p>
        <p>
          <strong>Information we collect automatically</strong>
        </p>
        <p>
          We automatically collect certain information from you when you use the Platform, including
          internet or other network activity information such as your IP address,
          geolocation-related data (as described below), unique device identifiers, browsing and
          search history (including content you have viewed in the Platform), and Cookies (as
          defined below).
        </p>
        <p>Usage Information</p>
        <p>
          We collect information regarding your use of the Platform and any other User Content that
          you generate through and broadcast on our Platform. We also link your subscriber
          information with your activity on our Platform across all your devices using your email,
          phone number, or similar information.
        </p>
        <p>Device Information</p>
        <p>
          We collect information about the device you use to access the Platform, including your IP
          address, unique device identifiers, model of your device, your mobile carrier, time zone
          setting, screen resolution, operating system, app and file names and types, keystroke
          patterns or rhythms, and platform.
        </p>
        <p>Location data</p>
        <p>
          We collect information about your location, including location information based on your
          SIM card and/or IP address. With your permission, we may also collect Global Positioning
          System (GPS) data.
        </p>
        <p>Messages</p>
        <p>
          We collect and process, which includes scanning and analyzing, information you provide in
          the context of composing, sending, or receiving message through the Platform&rsquo;s
          messaging functionality. That information includes the content of the message and
          information about when the message has been sent, received and/or read, as well as the
          participants of the communication. Please be aware that messages sent to other users of
          the Platform will be accessible by those users and that we are not responsible for the
          manner in which those users use or disclose messages.
        </p>
        <p>Metadata</p>
        <p>
          When you upload User Content, you automatically upload certain metadata that is connected
          to the User Content. Metadata describes other data and provides information about your
          User Content that will not always be evident to the viewer. In connection with your User
          Content the metadata can describe how, when, and by whom the piece of User Content was
          collected and how that content is formatted. It also includes information, such as your
          account name, that enables other users to trace back the User Content to your user
          account. Additionally, metadata will consist of data that you chose to provide with your
          User Content, e.g. any hashtags used to mark keywords to the video and captions.
        </p>
        <p>Cookies</p>
        <p>
          We and our service providers and business partners use cookies and other similar
          technologies (e.g. web beacons, flash cookies, etc.) (&ldquo;Cookies&rdquo;) to
          automatically collect information, measure and analyze which web pages you click on and
          how your use the Platform, enhance your experience using the Platform, improve the
          Platform, and provide you with targeted advertising on the Platform and elsewhere across
          your different devices. Cookies are small files which, when placed on your device, enable
          the Platform to provide certain features and functionality. Web beacons are very small
          images or small pieces of data embedded in images, also known as &ldquo;pixel tags&rdquo;
          or &ldquo;clear GIFs,&rdquo; that can recognize Cookies, the time and date a page is
          viewed, a description of the page where the pixel tag is placed, and similar information
          from your computer or device. To learn how to disable Cookies, see your &ldquo;Your
          choices&rdquo; section below.
        </p>
        <p>
          Additionally, we allow these service providers and business partners to collect
          information about your online activities through Cookies. We and our service providers and
          business partners link your contact or subscriber information with your activity on our
          Platform across all your devices, using your email or other log-in or device information.
          Our service providers and business partners may use this information to display
          advertisements on our Platform and elsewhere online and across your devices tailored to
          your interests, preferences, and characteristics. We are not responsible for the privacy
          practices of these service providers and business partners, and the information practices
          of these service providers and business partners are not covered by this Privacy Policy.
        </p>
        <p>
          We may aggregate or de-identify the information described above. Aggregated or
          de-identified data is not subject to this Privacy Policy.
        </p>
        <p>
          <strong>Your Rights</strong>
        </p>
        <p>
          You may submit a request to access or delete the information we have collected about you
          by sending your request to us at the email or physical address provided in the Contact
          section at the bottom of this policy. You may be entitled, in accordance with applicable
          law, to submit a request through an authorized agent. To designate an authorized agent to
          exercise choices on your behalf, please provide evidence that you have given such agent
          power of attorney or that the agent otherwise has valid written authority to submit
          requests to exercise rights on your behalf. We will respond to your request consistent
          with applicable law and subject to proper verification. We will verify your request by
          asking you to send it from the email address associated with your account or to provide
          information necessary to verify your account. And we do not discriminate based on the
          exercise of any privacy rights that you might have.
        </p>
        <p>
          <strong>Your Choices</strong>
        </p>
        <ul>
          <li>
            You may be able to refuse or disable Cookies by adjusting your browser settings. Because
            each browser is different, please consult the instructions provided by your browser.
            Please note that you may need to take additional steps to refuse or disable certain
            types of Cookies. For example, due to differences in how browsers and mobile apps
            function, you may need to take different steps to disable Cookies used for targeted
            advertising in a browser and to disable targeted advertising for a mobile application,
            which you may control through your device settings or mobile app permissions. In
            addition, our choice to disable cookies is specific to the particular browser or device
            that you are using when you disable cookies, so you may need to separately disable
            cookies for each type of browser or device. If you choose to refuse, disable, or delete
            Cookies, some of the functionality of the Platform may no longer be available to you.
            Without this information, we are not able to provide you with all the requested
            services, and any differences in services are related to your information.
          </li>
          <li>
            Your mobile device may include a feature that allows you to opt out of some types of
            targeted advertising (&ldquo;Limit Ad Tracking&rdquo; on iOS and &ldquo;Opt out of
            Interest-Based Ads&rdquo; on Android).
          </li>
          <li>
            You can switch off GPS location information functionality on your mobile device if you
            do not wish to share GPS information.
          </li>
          <li>
            If you have registered for an account you may access, review, and update certain
            personal information that you have provided to us by logging into your account and using
            available features and functionalities.
          </li>
          <li>
            Some browsers transmit &ldquo;do-not-track&rdquo; signals to websites. Because of
            differences in how browsers incorporate and activate this feature, it is not always
            clear whether users intend for these signals to be transmitted, or whether they even are
            aware of them. We currently do not take action in response to these signals.
          </li>
        </ul>
        <p>
          <strong>Security</strong>
        </p>
        <p>
          We use reasonable measures to help protect information from loss, theft, misuse and
          unauthorized access, disclosure, alteration, and destruction. You should understand that
          no data storage system or transmission of data over the Internet or any other public
          network can be guaranteed to be 100 percent secure. Please note that information collected
          by third parties may not have the same security protections as information you submit to
          us, and we are not responsible for protecting the security of such information.
        </p>
        <p>
          <strong>Changes</strong>
        </p>
        <p>
          We may update this Privacy Policy from time to time. When we update the Privacy Policy, we
          will notify you by updating the &ldquo;Last Updated&rdquo; date at the top of this policy
          and posting the new Privacy Policy and providing any other notice required by applicable
          law. We recommend that you review the Privacy Policy each time you visit the Platform to
          stay informed of our privacy practices.
        </p>
        {/* Add more paragraphs as needed */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
