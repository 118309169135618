import React, { useContext, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { PreferencesContext } from '../context/theme';
import { logIn } from '../redux/features/authSlice';
import { setSeller, setProvider } from '../redux/features/sellerSlice';
import { ButtonGeneral } from '../components/buttonGeneral';
import { InputGeneral } from '../components/inputGeneral';
import { PieIcons } from '../components/icons/PIE_Icons';
import logo from '../PIELogo.png';
import './styles/Login.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useContext(PreferencesContext);
  const [visible, setVisible] = useState(false);
  const seller = useSelector((state) => state.seller.seller);
  const [error, setError] = useState(null);

  const passwordRef = useRef(null);

  const showPass = () => {
    setVisible(!visible);
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const handleLogin = async (values) => {
    try {
      const result = await dispatch(logIn(values)).unwrap();

      dispatch(setSeller(result.seller));
    } catch (error) {
      console.error('LOGIN ERROR:', error);
      setError(error);
    }
  };

  useEffect(() => {
    if (seller) {
      if (Array.isArray(seller.oauthTokens) && seller.oauthTokens.length > 0) {
        const provider = seller.oauthTokens[0].provider;
        dispatch(setProvider(provider));
        navigate('/inventory');
      } else {
        navigate('/choose-pos');
      }
    }
  }, [seller, navigate]);

  return (
    <div className='login-container'>
      <button
        className='back-button'
        type='button'
        onClick={() => navigate('/')}>
        <span>&lt; Back</span>
      </button>

      <img
        src={logo}
        alt='Shop On PIE Logo'
        className='logo'
      />

      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleLogin(values)}>
        {({ handleBlur, handleSubmit, setFieldValue, values, errors, touched }) => (
          <div className='form-container'>
            <h2 className='title'>Seller Login</h2>

            <InputGeneral
              placeholder='Email'
              label='Email'
              value={values.email}
              type='email'
              onChange={(e) => setFieldValue('email', e.target.value)}
              onBlur={handleBlur('email')}
              errors={errors.email && touched.email ? errors.email || error : undefined}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (values.email) {
                    passwordRef.current.focus();
                  }
                }
              }}
            />

            <InputGeneral
              placeholder='Password'
              label='Password'
              value={values.password}
              onChange={(e) => setFieldValue('password', e.target.value)}
              onBlur={handleBlur('password')}
              errors={error}
              secure={!visible}
              iconR={
                <PieIcons
                  name='eye'
                  draw={{
                    size: 28,
                    onClick: showPass,
                    isVisible: visible,
                  }}
                />
              }
              ref={passwordRef} // Attach ref to password input
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (values.email && values.password) {
                    handleSubmit();
                  }
                }
              }}
            />

            <ButtonGeneral
              title='Log In'
              onPress={handleSubmit}
              color={theme.colors.secondary}
              textColor={theme.colors.textDark}
              fontSize={20}
              width
              type='submit'
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Login;
